import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const DatePicker = ({ onApply, settings = {}, value, ...props }) => (
  <div className="input-group date datepicker">
    <div className="input-group-addon">
      <span className="glyphicon glyphicon-calendar"></span>
    </div>
    <DateRangePicker
      initialSettings={{
        locale: {
          format: 'YYYY-MM-DD',
        },
        singleDatePicker: true,
        autoApply: true,
        ...settings,
      }}
      onApply={(events, picker) => {
        if (onApply) {
          onApply(picker.startDate.format('YYYY-MM-DD'));
        }
      }}
      {...props}
    >
      <div className="form-control">{value}</div>
    </DateRangePicker>
  </div>
);

DatePicker.propTypes = {
  value: PropTypes.string,
  settings: PropTypes.object,
  onApply: PropTypes.func,
};

export default DatePicker;
