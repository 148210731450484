import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { queryCampaignStatistics } from '../apis/CampaignApi';
import { useLoading } from '../providers/LoadingProvider';
import DatesPicker from '../components/Campaign/DatesPicker';
import StatisticsSummary from '../components/Campaign/StatisticsSummary';
import StatisticsAreas from '../components/Campaign/StatisticsAreas';
import StatisticsDrivers from '../components/Campaign/StatisticsDrivers';
import CampaignStatisticsMap from '../components/Campaign/CampaignStatisticsMap';
import VehicleGallery from '../components/Campaign/VehicleGallery';
import { useCampaign } from '../providers/CampaignProvider';
import { getLastDaysFromCampaign } from '../utils/helpers';
import CampaignLayout from '../components/Campaign/CampaignLayout';
import { hasRoleSlug } from '../utils/auth';
import StatisticsDriversMedia from '../components/Campaign/StatisticsDriversMedia';
function CampaignPage() {
  const { campaignId } = useParams();
  const { campaign } = useCampaign(); // load local campaign
  const [selectedDates, setSelectedDates] = useState({});
  const [showGallery, setShowGallery] = useState(false);
  // fetch campaign statistics
  const {
    isLoading,
    error,
    isSuccess,
    data = {},
  } = queryCampaignStatistics(
    {
      campaignId,
      ...selectedDates,
    },
    {
      enabled: !!(campaign && selectedDates.startDate && selectedDates.endDate),
    },
  );
  const { statistics } = data;

  const { toggleLoading } = useLoading(); // loading
  useEffect(() => {
    toggleLoading({ isLoading, error });
  }, [isLoading]);

  // build initial selected dates
  useEffect(() => {
    setSelectedDates(getLastDaysFromCampaign(campaign));
  }, [campaign]);

  const onFetchData = (dates = {}) => {
    setSelectedDates(dates);
  };

  if (isSuccess) {
    return (
      <CampaignLayout>
        <div className="row mb-3 align-items-center">
          <div className="col">
            {!hasRoleSlug('media') && (
              <Button
                variant="secondary"
                onClick={() => setShowGallery(!showGallery)}
              >
                <i className="fa fa-picture-o"></i>
                <span className="d-none d-sm-inline"> Vehicle Gallery</span>
              </Button>
            )}
          </div>
          <div className="col col-auto">
            <DatesPicker
              {...selectedDates}
              campaign={campaign}
              onApply={(dates) => {
                onFetchData(dates);
              }}
            />
          </div>
        </div>

        <VehicleGallery
          drivers={statistics.drivers}
          show={showGallery}
          onHide={() => setShowGallery(false)}
        />
        <CampaignStatisticsMap statistics={statistics} campaign={campaign} />
        <StatisticsSummary
          statistics={statistics.summary}
          campaign={campaign}
        />
        <StatisticsAreas statistics={statistics} />
        {!hasRoleSlug('media') && (
          <StatisticsDrivers statistics={statistics} campaign={campaign} />
        )}
        {hasRoleSlug('media') && (
          <StatisticsDriversMedia statistics={statistics} campaign={campaign} />
        )}
      </CampaignLayout>
    );
  }
  return null;
}

export default CampaignPage;
