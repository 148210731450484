import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import CampaignLayout from '../components/Campaign/CampaignLayout';
import { SWARM_EVENT_STATUS } from '../constains/variables';
import { querySwarmEvent } from '../apis/CampaignApi';
import { useLoading } from '../providers/LoadingProvider';
import DetailSwarmInfo from '../components/Campaign/SwarmEvent/DetailSwarm/DetailSwarmInfo';
import DetailSwarmMap from '../components/Campaign/SwarmEvent/DetailSwarm/DetailSwarmMap';
import DetailSawrmDrivers from '../components/Campaign/SwarmEvent/DetailSwarm/DetailSwarmDrivers';
import SwarmGallery from '../components/Campaign/SwarmEvent/DetailSwarm/SwarmGallery';
import SwarmSummary from '../components/Campaign/SwarmEvent/SwarmSummary';

function CampaignDetailSwarmPage() {
  const { campaignId, eventId } = useParams();

  // fetch campaign statistics
  const { isLoading, error, isSuccess, data = {} } = querySwarmEvent(eventId);

  const { toggleLoading } = useLoading(); // loading
  useEffect(() => {
    toggleLoading({ isLoading, error });
  }, [isLoading]);

  if (isSuccess) {
    if (
      ![SWARM_EVENT_STATUS.LIVE, SWARM_EVENT_STATUS.FINISHED].includes(
        data.status,
      )
    )
      return <Redirect to={`/campaign/${campaignId}/swarming-events`} />;

    return (
      <CampaignLayout>
        <DetailSwarmInfo event={data} />
        {data.swarm_images && data.swarm_images.length > 0 && (
          <SwarmGallery images={data.swarm_images} />
        )}
        <DetailSwarmMap event={data} />
        <SwarmSummary statistics={data.statistics.summary} />
        <DetailSawrmDrivers drivers={data.drivers} />
      </CampaignLayout>
    );
  }
  return null;
}

export default CampaignDetailSwarmPage;
