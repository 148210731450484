import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from '../../../moment';
import Collapse from '../../../Collapse';
import FormattedNumber from '../../../Intl/FormattedNumber';

const DetailSwarmInfo = ({ event }) => {
  const startTime = moment(event.start_time, 'HH:mm:ss');

  return (
    <Collapse className="animated fadeIn ">
      <h2 className="m-b">
        <Link
          className="btn btn-secondary btn-xs"
          to={`/campaign/${event.campaign_id}/swarming-events`}
          title="Back to swarm events"
        >
          <i className="fa fa-angle-left"></i>
        </Link>{' '}
        {moment(event.swarm_date).format('Do MMMM YYYY')} - Swarm Event{' '}
        <span className={`badge badge-${event.status_stylish.style}`}>
          {event.status_stylish.label}
        </span>
      </h2>
      {startTime.format('hh:mmA')}
      {' - '}
      {startTime.add(event.duration, 'm').format('hh:mmA')}
      {', '}
      {event.location_name}
      {', '}{' '}
      <FormattedNumber
        value={event.number_of_cars}
        surfix={event.number_of_cars > 1 ? 'Cars' : 'Car'}
      />
    </Collapse>
  );
};

DetailSwarmInfo.propTypes = {
  event: PropTypes.object,
};

export default DetailSwarmInfo;
