import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { queryAccess } from '../apis/AuthApi';
import { useAuth } from '../providers/AuthProvider';
import { OFFICIAL_PAGE } from '../constains/variables';
import Loading from '../components/Loading';

function AccessPage() {
  const { uuid } = useParams();
  const { updateCurrentUser } = useAuth();
  const { isSuccess, isLoading, isError, data = [] } = queryAccess(uuid);
  useEffect(() => {
    if (isSuccess) {
      updateCurrentUser(data, false);
    }
  }, [isSuccess]);

  if (isSuccess) return <Redirect to="/" />;
  if (isError) {
    window.location = OFFICIAL_PAGE;
    return null;
  }
  return <Loading isLoading={isLoading} />;
}

export default AccessPage;
