import React from 'react';
import PropTypes from 'prop-types';
import { useIntercom } from 'react-use-intercom';
import moment from '../../moment';
import Collapse from '../../Collapse';

const Pending = ({ event }) => {
  const { showNewMessages } = useIntercom();
  const startTime = moment(event.start_time, 'HH:mm:ss');
  return (
    <Collapse className="animated fadeIn ">
      <h3 className="m-b">
        <b>Pending:</b> {moment(event.swarm_date).format('Do MMMM YYYY')}
        {', '}
        {startTime.format('hh:mmA')}
        {' - '}
        {startTime.add(event.duration, 'm').format('hh:mmA')}
        {', '}
        {event.location_name}
      </h3>
      Your request has been submitted.
      <div className="progress">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-success "
          role="progressbar"
          style={{ width: '33%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-warning "
          role="progressbar"
          style={{ width: '33%' }}
        />
      </div>
      <p className="m-t-md">
        We’ll contact you within 96hrs to confirm.
        <br /> If you have any questions or want to change your Domination
        Event, please contact with our team. <br />
        <b>Please note:</b> You cannot set a new Swarm event until we complete
        your current request.
      </p>
      <button
        type="button"
        onClick={() =>
          showNewMessages(
            'Hello, I would like to know about my carbon offset request process.',
          )
        }
        className="btn btn-primary btn-sm m-r"
      >
        Contact The Team
      </button>
    </Collapse>
  );
};

Pending.propTypes = {
  event: PropTypes.object,
};

export default Pending;
