import React from 'react';
import PropTypes from 'prop-types';
import { useIntercom } from 'react-use-intercom';
import Collapse from '../../Collapse';
import FormattedNumber from '../../Intl/FormattedNumber';

const Live = ({ carbonOffset }) => {
  const { showNewMessages } = useIntercom();
  return (
    <>
      <Collapse className="animated fadeIn ">
        <h2 className="m-b">Carbon Offseting is LIVE!</h2>
        <div className="progress m-b-lg">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            className="progress-bar progress-bar-striped bg-success "
            role="progressbar"
            style={{ width: '33%' }}
          />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            className="progress-bar progress-bar-striped bg-warning "
            role="progressbar"
            style={{ width: '33%' }}
          />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            className="progress-bar progress-bar-striped "
            role="progressbar"
            style={{ width: '34%' }}
          />
        </div>
        <h3>Thank you for taking extra care of our planet!</h3>
        <p>If you have questions, contact our team.</p>
        <button
          type="button"
          onClick={() =>
            showNewMessages(
              'Hello, I would like to know about my digital request process.',
            )
          }
          className="btn btn-primary btn-sm m-r"
        >
          Contact The Team
        </button>
      </Collapse>
      {carbonOffset && carbonOffset.report && (
        <Collapse className="animated fadeIn ">
          <div className="row align-items-end">
            <div className="col-12 col-sm-4 text-center profile-impact-item">
              <div className="row align-items-center">
                <div className="col-6 col-sm-12">
                  <img
                    className="profile-impact-item-image"
                    src="/impact-tree.png"
                    alt=""
                  />
                </div>
                <div className="col-6 col-sm-12">
                  <div className="profile-impact-item-value">
                    <FormattedNumber value={carbonOffset.report.tree_planed} />
                  </div>
                  <div className="profile-impact-item-label">Tree planted</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 text-center profile-impact-item">
              <div className="row  align-items-center">
                <div className="col-6 col-sm-12">
                  <img
                    className="profile-impact-item-image"
                    src="/impact-carbon.png"
                    alt=""
                  />
                </div>
                <div className="col-6 col-sm-12">
                  <div className="profile-impact-item-value">
                    <FormattedNumber
                      value={carbonOffset.report.tone_of_carbon_removed}
                      maximumFractionDigits={2}
                    />
                    t
                  </div>
                  <div className="profile-impact-item-label">
                    Tones of carbon removed
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 text-center profile-impact-item">
              <div className="row  align-items-center">
                <div className="col-6 col-sm-12">
                  <img
                    className="profile-impact-item-image"
                    src="/impact-trees.png"
                    alt=""
                  />
                </div>
                <div className="col-6 col-sm-12">
                  <div className="profile-impact-item-value">
                    <FormattedNumber
                      value={carbonOffset.report.sqm_area_supported}
                    />
                  </div>
                  <div className="profile-impact-item-label">
                    Sq/m area supported
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      )}
    </>
  );
};

Live.propTypes = {
  carbonOffset: PropTypes.object,
};

export default Live;
