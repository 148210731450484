import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { routes } from '../routes';
import { PROTECT_LAYOUT } from '../constains/variables';
import Footer from '../components/Footer';
import { TopMenu } from '../components/Navigation';
import { detectBody } from '../utils/helpers';
import Sidebar from '../components/Sidebar';
import CampaignProvider from '../providers/CampaignProvider';
import { usePrefetchCampaign } from '../hooks/usePrefetchCampaign';

const Layout = (props) => {
  const { campaignId } = useParams();
  const { campaign, digital, carbonOffset, swarmEvents } =
    usePrefetchCampaign(campaignId);
  useEffect(() => {
    // document.getElementById('wrapper').className = 'top-navigation';
    detectBody();
  }, []);
  return (
    <>
      <Sidebar
        campaign={campaign}
        digital={digital}
        carbonOffset={carbonOffset}
        swarmEvents={swarmEvents}
      />
      <div id="page-wrapper" className="gray-bg">
        <div className="row border-bottom">
          <TopMenu />
        </div>
        <div className="row">
          <div
            id="main-container"
            className="container-fluid animated fadeInRight"
          >
            {/* eslint-disable-next-line react/prop-types */}
            {(!campaignId || (campaignId && campaign)) && props.children}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

function ProtectLayout() {
  return (
    <CampaignProvider>
      <Switch>
        {routes.map((route, idx) => {
          if (route.layout === PROTECT_LAYOUT) {
            return (
              <Route
                key={idx.toString()}
                path={route.path}
                render={(routeProps) => (
                  <Layout>
                    <route.component {...routeProps} />
                  </Layout>
                )}
              />
            );
          }
          return null;
        })}
      </Switch>
    </CampaignProvider>
  );
}

export default ProtectLayout;
