import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';
export const LoadingContext = createContext({
  toggleLoading: () => {},
});

export const useLoading = () => useContext(LoadingContext);

const LoadingProvider = (props) => {
  const [state, setState] = useState(false);
  const toggleLoading = (newState) => {
    setState(newState);
  };
  return (
    <LoadingContext.Provider value={{ toggleLoading }}>
      <Loading
        {...state}
        onClose={() => setState({ isLoading: false, error: null })}
      />
      {props.children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.any,
};

export default LoadingProvider;
