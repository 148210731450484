import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DrawingManager from './DrawingManager';
import Gmap from '../GMap';
import { useDrawMap } from '../../hooks/useDrawMap';
import AreaLayer from './AreaLayer';
const DrawMap = ({
  id = 'drawmap',
  zoom,
  isMultiple = true,
  areas = [],
  mapContainerStyle,
  initDrawAreas = [],
  drawingControlOptions = {},
  drawingControl = true,
  onOverlayUpdate,
  onOverlayModified,
  onValidation,
  onLoad,
}) => {
  let drawAreas = [];

  const { addDrawArea, removeDrawAreas } = useDrawMap();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleOverlayComplete = (area) => {
    drawAreas = addDrawArea(area);
    handleUpdateAreas();
  };

  const handleDelete = () => {
    // if is not multiple, delete all
    drawAreas = removeDrawAreas(!isMultiple);
    handleUpdateAreas();
  };

  const handleUpdateAreas = () => {
    if (onValidation) {
      if (drawAreas.length > 0) onValidation(drawAreas[drawAreas.length - 1]);
      else onValidation(null);
    }
    if (onOverlayUpdate) {
      if (isMultiple) onOverlayUpdate(drawAreas);
      else if (drawAreas.length > 0) onOverlayUpdate(drawAreas[0]);
    }
  };

  const handleOverlayModified = (overlay) => {
    if (onValidation) onValidation(overlay);
    if (onOverlayModified) onOverlayModified(overlay);
  };

  return useMemo(
    () => (
      <Gmap
        id={id}
        zoom={zoom}
        mapContainerStyle={mapContainerStyle}
        onLoad={(map) => {
          if (onLoad) onLoad(map);
        }}
      >
        <DrawingManager
          initDrawAreas={initDrawAreas}
          drawingControl={drawingControl}
          drawingControlOptions={drawingControlOptions}
          isMultiple={isMultiple}
          onOverlayComplete={handleOverlayComplete}
          onOverlayModified={handleOverlayModified}
          onDelete={drawingControl ? handleDelete : null}
        />
        {areas && areas.map((area) => <AreaLayer key={area.id} area={area} />)}
      </Gmap>
    ),
    [],
  );
};

DrawMap.propTypes = {
  id: PropTypes.string,
  zoom: PropTypes.number,
  center: PropTypes.any,
  isMultiple: PropTypes.bool,
  areas: PropTypes.array,
  initDrawAreas: PropTypes.array,
  mapContainerStyle: PropTypes.object,
  drawingControlOptions: PropTypes.object,
  drawingControl: PropTypes.bool,
  onOverlayUpdate: PropTypes.func,
  onOverlayModified: PropTypes.func,
  onValidation: PropTypes.func,
  onLoad: PropTypes.func,
};
export default DrawMap;
