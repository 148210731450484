import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { OFFICIAL_PAGE } from '../constains/variables';
import { mutationSignIn } from '../apis/AuthApi';
import { useAuth } from '../providers/AuthProvider';
import Field from '../components/ExForm/Field';
import Checkbox from '../components/ExForm/Checkbox';

function LoginPage() {
  const { updateCurrentUser } = useAuth();
  const mutation = mutationSignIn();
  const { isLoading, isError, error, isSuccess, data, variables } = mutation;
  useEffect(() => {
    if (isSuccess) {
      updateCurrentUser(data, variables.remember || false);
    }
  }, [isSuccess]);
  if (isSuccess) return <Redirect to="/" />;
  return (
    <div className="animated fadeInRight">
      <h2 className="text-white">Welcome to Drovo Dashboard</h2>
      <div className="ibox-content m-t text-left">
        <Formik
          initialValues={{ email: '', password: '', remember: false }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!values.password) {
              errors.password = 'Password is required';
            }
            return errors;
          }}
          onSubmit={(values) => mutation.mutate(values)}
        >
          {(form) => (
            <Form onSubmit={form.handleSubmit}>
              {isError && (
                <div className="alert alert-danger text-left">{error}</div>
              )}
              <Field
                type="email"
                name="email"
                placeholder="Email"
                prependIcon="glyphicon glyphicon-envelope"
                form={form}
              />
              <Field
                type="password"
                name="password"
                placeholder="Password"
                prependIcon="glyphicon glyphicon-lock"
                form={form}
              />
              <Checkbox name="remember" label="Remember me" />
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-primary block full-width m-b"
              >
                Login {isLoading && <i className="fa fa-spinner fa-spin"></i>}
              </button>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <Link to="/auth/forgot-password">
            <small>Forgot password?</small>
          </Link>
          <p className=" text-muted">
            <small>Do not have an account?</small>
          </p>
          <a
            className="btn btn-sm btn-white btn-block"
            href={`${OFFICIAL_PAGE}signup/demo`}
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
