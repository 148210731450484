import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useGoogleMap } from '@react-google-maps/api';
/* eslint-disable */
const MapSelectedDrivers = ({ control, drivers, onSelectDrivers }) => {
  const ref = createRef();
  const map = useGoogleMap();

  useEffect(() => {
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(ref.current);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        margin: '10px 10px',
        backgroundColor: '#FFF',
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
      }}
    >
      <Select
        menuPortalTarget={ref.current}
        isDisabled={control.act === 'play'}
        styles={{
          control: (provided) => ({
            ...provided,
            border: 0,
          }),
          multiValue: (provided) => ({
            ...provided,
            display: 'none',
          }),
          placeholder: (provided) => ({
            ...provided,
            display: 'none',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '2px 0px',
          }),
          option: (styles, { data, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? data.styleColor
              : styles.backgroundColor,
            color: isSelected ? '#ffffff' : data.styleColor,
            fontWeight: data.value === 'all' ? 900 : styles.fontWeight,
            display:
              data.value === 'all' && isSelected ? 'none' : styles.display,
          }),
        }}
        components={{
          IndicatorsContainer: ({ children, ...props }) => {
            const numberRoutes = props.getValue().length;
            let label = 'All Drivers';
            if (numberRoutes > 0) {
              label = `Show ${numberRoutes} ${
                numberRoutes > 1 ? 'drivers' : 'driver'
              }`;
            }
            return (
              <components.IndicatorsContainer {...props}>
                <div
                  style={{
                    color: 'rgb(86, 86, 86)',
                    fontFamily: 'Roboto, Arial, sans-serif',
                    fontSize: '18px',
                    lineHeight: '40px',
                    padding: '0px 17px',
                  }}
                >
                  <i className="fa fa-map-signs"></i> {label}
                </div>
                {children}
              </components.IndicatorsContainer>
            );
          },
          Menu: (props) => (
            <components.Menu {...props}>
              <div
                onClick={() =>
                  props.setValue(
                    props.getValue().length !== props.options.length
                      ? props.options
                      : null,
                  )
                }
                style={{
                  cursor: 'pointer',
                  display: 'block',
                  fontWeight: 900,
                  padding: '8px 12px',
                }}
              >
                <i className="fa fa-users"></i>
                {props.getValue().length !== props.options.length
                  ? ' Select All'
                  : ' Unselect All'}
              </div>
              {props.children}
            </components.Menu>
          ),
        }}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={drivers.map((driver) => ({
          label: `${driver.driver.driver_id}`,
          value: parseInt(driver.driver_id, 10),
          styleColor: driver.style,
        }))}
        isSearchable={false}
        onChange={(sel) =>
          onSelectDrivers((sel || []).map((item) => parseInt(item.value, 10)))
        }
      />
    </div>
  );
};

MapSelectedDrivers.propTypes = {
  control: PropTypes.object,
  drivers: PropTypes.array,
  onSelectDrivers: PropTypes.func,
};
export default MapSelectedDrivers;
