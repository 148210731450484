import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from '../moment';

const DatesPicker = ({ campaign, startDate, endDate, onApply, ...props }) => {
  const months = campaign.number_of_months;
  const activeDrivers = parseInt(campaign.number_of_cars, 10);
  const ranges = [];
  for (let i = 0; i < months; i += 1) {
    const value = i + 1;
    const label = `${activeDrivers < 10 ? 'month' : 'week'}${
      value > 1 ? 's' : ''
    }`;
    ranges[`${value} ${label}`] = [
      moment().subtract(value, label).format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ];
  }
  return (
    <DateRangePicker
      {...props}
      initialSettings={{
        ranges,
        locale: {
          format: 'YYYY-MM-DD',
        },
        startDate,
        endDate,
        minDate: campaign.beginning_date,
        maxDate: campaign.end_date,
        autoApply: false,
      }}
      onApply={(events, picker) => {
        onApply({
          startDate: picker.startDate.format('YYYY-MM-DD'),
          endDate: picker.endDate.format('YYYY-MM-DD'),
        });
      }}
    >
      <div className="input-group date datepicker">
        <div className="input-group-addon">
          <span className="glyphicon glyphicon-calendar"></span>
        </div>
        <span type="text" className="form-control">
          from <b>{startDate}</b> to <b>{endDate}</b>
        </span>
      </div>
    </DateRangePicker>
  );
};

DatesPicker.propTypes = {
  campaign: PropTypes.object,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onApply: PropTypes.func,
};

export default DatesPicker;
