import React from 'react';
import PropTypes from 'prop-types';
import { DrawingManager as GDrawingManager } from '@react-google-maps/api';
import { MAP_CONFIG } from '../../constains/variables';
import DeleteDraw from './DeleteDraw';
import InitDrawAreas from './InitDrawAreas';
const DrawingManager = ({
  isMultiple = true,
  drawingControl = true,
  initDrawAreas = [],
  ...props
}) => {
  const drawOptions = {
    ...MAP_CONFIG.drawOptions,
    draggable: drawingControl,
    clickable: drawingControl,
    editable: drawingControl,
    zIndex: 1,
    ...(props.drawOptions || {}),
  };
  let drawingManager;

  const handleOverlayComplete = (overlay) => {
    if (drawingManager) {
      drawingManager.setDrawingMode(null);
      if (!isMultiple) {
        drawingManager.setOptions({
          drawingControl: false,
        });
      }
    }

    if (props.onOverlayModified) {
      switch (overlay.type) {
        case 'distance':
        case 'circle':
        case 'postcode':
          window.google.maps.event.addListener(
            overlay.overlay,
            'radius_changed',
            (evt) => props.onOverlayModified(overlay, evt),
          );
          break;
        case 'rectangle':
        case 'bounding_box':
          window.google.maps.event.addListener(
            overlay.overlay,
            'bounds_changed',
            (evt) => props.onOverlayModified(overlay, evt),
          );
          break;
        default:
          // polygon
          // eslint-disable-next-line no-case-declarations
          const path = overlay.overlay.getPath();
          path.addListener('set_at', (evt) =>
            props.onOverlayModified(overlay, evt),
          );
          path.addListener('insert_at', (evt) =>
            props.onOverlayModified(overlay, evt),
          );
          break;
      }
      props.onOverlayModified(overlay);
    }
    if (props.onOverlayComplete) props.onOverlayComplete(overlay);
  };

  const handleLoad = (dm) => {
    drawingManager = dm;
  };

  return (
    <>
      <GDrawingManager
        options={{
          drawingControl,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['circle', 'polygon', 'rectangle'],
            ...(props.drawingControlOptions || {}),
          },
          polygonOptions: drawOptions,
          circleOptions: drawOptions,
          rectangleOptions: drawOptions,
        }}
        onLoad={handleLoad}
        onOverlayComplete={handleOverlayComplete}
      />
      {initDrawAreas && initDrawAreas.length > 0 && (
        <InitDrawAreas
          drawOptions={drawOptions}
          areas={initDrawAreas}
          onOverlayComplete={handleOverlayComplete}
        />
      )}
      {props.onDelete && (
        <DeleteDraw
          onClick={() => {
            if (drawingManager) {
              drawingManager.setDrawingMode(null);
              props.onDelete(drawingManager);

              if (!isMultiple) {
                drawingManager.setOptions({
                  drawingControl: true,
                });
              }
            }
          }}
        />
      )}
    </>
  );
};

DrawingManager.propTypes = {
  isMultiple: PropTypes.bool,
  initDrawAreas: PropTypes.array,
  drawingControl: PropTypes.bool,
  drawOptions: PropTypes.object,
  drawingControlOptions: PropTypes.object,
  onDelete: PropTypes.func,
  onOverlayModified: PropTypes.func,
  onOverlayComplete: PropTypes.func,
};
export default DrawingManager;
