import { useMutation, useQuery } from 'react-query';
import request from '../utils/request';
import { randomColors } from '../utils/helpers';
import { hasRoleSlug } from '../utils/auth';
import { QUERY_CACHE_TIME } from '../constains/variables';
import queryClient from './query_client';

// name of queries
export const QUERY_CAMPAIGNS = 'apis/CampaignApi/QUERY_CAMPAIGNS';
export const QUERY_CAMPAIGN = 'apis/CampaignApi/QUERY_CAMPAIGN';
export const QUERY_CAMPAIGN_DIGITAL = 'apis/CampaignApi/QUERY_CAMPAIGN_DIGITAL';
export const QUERY_CAMPAIGN_CARBON_OFFSET =
  'apis/CampaignApi/QUERY_CAMPAIGN_CARBON_OFFSET';
export const QUERY_CAMPAIGN_STATISTICS =
  'apis/CampaignApi/QUERY_CAMPAIGN_STATISTICS';
export const QUERY_CAMPAIGN_SWARM_EVENTS =
  'apis/CampaignApi/QUERY_CAMPAIGN_SWARM_EVENTS';
export const QUERY_CAMPAIGN_SWARM_EVENT =
  'apis/CampaignApi/QUERY_CAMPAIGN_SWARM_EVENT';
// data api

export const queryCampaigns = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(QUERY_CAMPAIGNS, () => request(`campaigns?filter=*`), {
    staleTime: QUERY_CACHE_TIME,
  });

export const queryCampaign = (id, options = {}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(
    [QUERY_CAMPAIGN, id],
    () => {
      if (id)
        return request(`campaign/${id}`).then((res) => {
          if (hasRoleSlug('media')) {
            res.wrap_images = [];
            res.name = 'Sample Campaign';
          }
          return res;
        });
      throw new Error('Oh no! There is no campaign has been selected');
    },
    {
      staleTime: QUERY_CACHE_TIME,
      enabled: !!id,
      ...options,
    },
  );

export const queryCampaignStatistics = (filter = {}, options = {}) => {
  const fetchStatisticsCampaign = (data) =>
    request(`campaign_statistics`, {
      body: {
        from_time: data.from_time || data.startDate,
        to_time: data.to_time || data.endDate,
        campaign_id: data.campaignId,
      },
      method: 'post',
    }).then((res) => {
      const randColors = randomColors(res.statistics.drivers.length);
      res.statistics.drivers = res.statistics.drivers.filter((driver) =>
        ['run', 'suspend', 'finish'].includes(driver.driver.status),
      );
      res.statistics.drivers.forEach((driver, index) => {
        // eslint-disable-next-line no-param-reassign
        driver.style = randColors[index];
      });
      return res;
    });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    [QUERY_CAMPAIGN_STATISTICS, filter],
    () => fetchStatisticsCampaign(filter),
    {
      staleTime: QUERY_CACHE_TIME,
      ...options,
    },
  );
};

export const queryCampaignDigital = (id, options = {}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(
    [QUERY_CAMPAIGN_DIGITAL, id],
    () => {
      if (id) return request(`digital?campaign_id=${id}`);
      throw new Error('Oh no! There is no campaign has been selected');
    },
    {
      staleTime: QUERY_CACHE_TIME,
      enabled: !!id,
      onSuccess: (data) => {
        // eslint-disable-next-line no-param-reassign
        data.status = data.status || 'new';
        // eslint-disable-next-line no-param-reassign
        data.status_stylish = data.status_stylish || {
          label: 'Request',
          style: 'default',
        };
      },
      ...options,
    },
  );
export const mutationCampaignDigital = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation(
    (id) =>
      request(`digital?campaign_id=${id}`, {
        method: 'post',
      }),
    {
      onSuccess: (data, campaignId) => {
        queryClient.setQueryData(
          [QUERY_CAMPAIGN_DIGITAL, campaignId.toString()],
          data,
        );
      },
    },
  );

export const mutationCampaignDigitalRequestReport = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation(
    ({ id, reportType }) =>
      request(`digital/${id}/request-report`, {
        method: 'post',
        body: { reportType },
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [QUERY_CAMPAIGN_DIGITAL, data.campaign_id.toString()],
          data,
        );
      },
    },
  );

export const queryCampaignCarbonOffset = (id, options = {}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(
    [QUERY_CAMPAIGN_CARBON_OFFSET, id],
    () => {
      if (id) return request(`carbon-offset?campaign_id=${id}`);
      throw new Error('Oh no! There is no campaign has been selected');
    },
    {
      staleTime: QUERY_CACHE_TIME,
      enabled: !!id,
      onSuccess: (data) => {
        // eslint-disable-next-line no-param-reassign
        data.status = data.status || 'new';
        // eslint-disable-next-line no-param-reassign
        data.status_stylish = data.status_stylish || {
          label: 'Request',
          style: 'default',
        };
      },
      ...options,
    },
  );
export const mutationCampaignCarbonOffset = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation(
    (id) =>
      request(`carbon-offset?campaign_id=${id}`, {
        method: 'post',
      }),
    {
      onSuccess: (data, campaignId) => {
        queryClient.setQueryData(
          [QUERY_CAMPAIGN_CARBON_OFFSET, campaignId.toString()],
          data,
        );
      },
    },
  );

export const querySwarmEvents = (campaignId, options = {}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(
    [QUERY_CAMPAIGN_SWARM_EVENTS, campaignId],
    () => {
      if (campaignId) return request(`swarm-events?campaign_id=${campaignId}`);
      throw new Error('Oh no! There is no campaign has been selected');
    },
    {
      staleTime: QUERY_CACHE_TIME,
      enabled: !!campaignId,
      ...options,
    },
  );

export const mutationSwarmEvent = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation(
    (body) =>
      request(`swarm-events`, {
        method: 'post',
        body,
      }),
    {
      onSuccess: (data) => {
        const swarms = queryClient.getQueryData([
          QUERY_CAMPAIGN_SWARM_EVENTS,
          data.campaign_id.toString(),
        ]);
        queryClient.setQueryData(
          [QUERY_CAMPAIGN_SWARM_EVENTS, data.campaign_id.toString()],
          [...swarms.filter((swarm) => swarm.id !== data.id), data],
        );
      },
    },
  );

export const querySwarmEvent = (eventId, options = {}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(
    [QUERY_CAMPAIGN_SWARM_EVENT, eventId],
    () => {
      if (eventId)
        return request(`swarm-events/${eventId}`).then((res) => {
          const randColors = randomColors(res.statistics.drivers.length);
          res.statistics.drivers.forEach((driver, index) => {
            // eslint-disable-next-line no-param-reassign
            driver.style = randColors[index];
          });
          return res;
        });
      throw new Error('Oh no! There is no swarm event has been selected');
    },
    {
      // staleTime: QUERY_CACHE_TIME,
      enabled: !!eventId,
      ...options,
    },
  );
