import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Modal, Alert } from 'react-bootstrap';
import Collapse from '../../Collapse';
import { useCampaign } from '../../../providers/CampaignProvider';
import {
  CAMPAIGN_STATUS,
  DIGITAL_REPORT_TYPE,
  DIGITAL_STATUS,
} from '../../../constains/variables';
import { mutationCampaignDigitalRequestReport } from '../../../apis/CampaignApi';
import { useLoading } from '../../../providers/LoadingProvider';

const RequestReport = () => {
  const [showModal, setShowModal] = useState(false);
  const { campaign, digital } = useCampaign();
  const report = digital ? digital.report : {};
  const mutationRequestReport = mutationCampaignDigitalRequestReport();

  const { toggleLoading } = useLoading(); // loading
  useEffect(() => {
    toggleLoading({ ...mutationRequestReport });
  }, [mutationRequestReport.isLoading]);

  useEffect(() => {
    setShowModal(mutationRequestReport.isSuccess);
  }, [mutationRequestReport.isSuccess]);

  if (
    !digital ||
    ![DIGITAL_STATUS.LIVE, DIGITAL_STATUS.CONFIRMED].includes(digital.status)
  )
    return null;

  return (
    <Collapse className="animated fadeIn ">
      {campaign.status === CAMPAIGN_STATUS.RUN && (
        <div className="mb-4">
          <p>Get your mid-camapign and end of campaign report</p>
          <button
            disabled={!!report[DIGITAL_REPORT_TYPE.REPORT_NORMAL]}
            type="button"
            onClick={() =>
              mutationRequestReport.mutate({
                id: digital.id,
                reportType: DIGITAL_REPORT_TYPE.REPORT_NORMAL,
              })
            }
            className="btn btn-primary btn-sm"
          >
            Request campaign’s report
          </button>
          {!!report[DIGITAL_REPORT_TYPE.REPORT_NORMAL] && (
            <Alert variant="success" className="mt-3">
              We will be in touch to confirm the details shortly.
            </Alert>
          )}
        </div>
      )}
      Display your campaign’s data on your own visualisation tool as soon as it
      goes live <br />
      <p className="small">
        (limited number of tools are supported - once you submit your request,
        our team will get in touch for more details)
      </p>
      <button
        disabled={!!report[DIGITAL_REPORT_TYPE.REPORT_1X1]}
        type="button"
        onClick={() =>
          mutationRequestReport.mutate({
            id: digital.id,
            reportType: DIGITAL_REPORT_TYPE.REPORT_1X1,
          })
        }
        className="btn btn-primary btn-sm"
      >
        Request 1x1 Pixel
      </button>
      {!!report[DIGITAL_REPORT_TYPE.REPORT_1X1] && (
        <Alert variant="success" className="mt-3">
          We will be in touch to confirm the details shortly.
        </Alert>
      )}
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Thank you for your submitting your request!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We will be in touch within 96 hours to confirm the details but please
          contact our team if you have questions in the meantime.
        </Modal.Body>
      </Modal>
    </Collapse>
  );
};

RequestReport.propTypes = {};

export default RequestReport;
