import $ from 'jquery';
import moment from '../components/moment';
import { CAMPAIGN_STATUS, SWARM_EVENT_STATUS } from '../constains/variables';

export function detectBody() {
  $(window).on('load resize', () => {
    if ($(document).width() < 769) {
      $('body').addClass('body-small');
    } else {
      $('body').removeClass('body-small');
    }
  });
}

export const convertMilesToMeters = (miles) => miles * 1609.344;
export const convertMetersToMiles = (meters) => meters * 0.000621371192;

export const convertDistanceUnit = (distance, convertUnit) => {
  switch (convertUnit) {
    case 'mile2meter':
      return convertMilesToMeters(distance);
    case 'meter2mile':
      return convertMetersToMiles(distance);
    default:
      return distance;
  }
};

export function campaignStatus(status) {
  let label;
  let style;
  switch (status) {
    case CAMPAIGN_STATUS.DRAFT:
      style = 'default';
      label = 'DRAFT';
      break;
    case CAMPAIGN_STATUS.APPLY:
      style = 'info';
      label = 'APPLIED';
      break;
    case CAMPAIGN_STATUS.MATCH:
      style = 'primary';
      label = 'MATCH';
      break;
    case CAMPAIGN_STATUS.RUN:
      style = 'warning';
      label = 'RUNNING';
      break;
    case CAMPAIGN_STATUS.FINISH:
      style = 'danger';
      label = 'FINISH';
      break;
    default:
      style = 'default';
      label = 'PAUSE';
  }

  return {
    style,
    label,
  };
}

export function swarmStylish(status) {
  switch (status) {
    case SWARM_EVENT_STATUS.PENDING:
      return { label: 'Pending', style: 'warning' };
    case SWARM_EVENT_STATUS.CONFIRMED:
      return { label: 'Confirmed', style: 'success' };
    case SWARM_EVENT_STATUS.LIVE:
      return { label: 'Live', style: 'primary' };
    case SWARM_EVENT_STATUS.FINISHED:
      return { label: 'Finished', style: 'danger' };
    default:
      return { label: 'Request', style: 'default' };
  }
}

export function randomColors(num) {
  const randColors = [];
  const rand = (brightness) => {
    function randomChannel() {
      const r = brightness;
      // eslint-disable-next-line no-bitwise
      const n = 0 | (brightness - Math.random() * r);
      const s = n.toString(16);
      return s.length === 1 ? `0${s}` : s;
    }
    return `#${randomChannel(brightness)}${randomChannel(
      brightness,
    )}${randomChannel(brightness)}`;
  };

  while (randColors.length < num) {
    const randomColor = rand(200);
    if (randColors.indexOf(randomColor) < 0) {
      randColors.push(randomColor);
    }
  }
  return randColors;
}

export function getThumbnail(url, style = 'medium') {
  const lastIndexOfDot = url.lastIndexOf('.');
  return `${url.slice(0, lastIndexOfDot)}_${style}Thumb.${url.slice(
    // eslint-disable-next-line no-bitwise
    ((lastIndexOfDot - 1) >>> 0) + 2,
  )}`;
}

export function getLastDaysFromCampaign(campaign) {
  if (!campaign) return {};
  const activeDrivers = parseInt(campaign.number_of_cars, 10);
  const showDuration = activeDrivers <= 10 ? 'months' : 'weeks';
  const currentDate = moment().format('YYYY-MM-DD');
  const endDate =
    currentDate > campaign.end_date ? campaign.end_date : currentDate;
  let startDate = moment(endDate)
    .subtract(1, showDuration)
    .format('YYYY-MM-DD');
  startDate =
    startDate < campaign.beginning_date ? campaign.beginning_date : startDate;
  return { startDate, endDate };
}

export function convertStyle(style) {
  switch (style) {
    case 'success':
      return 'navy';
    default:
      return style;
  }
}

export function objectReadyViewed(objId, objType) {
  const objectViewed = JSON.parse(
    localStorage.getItem('_objectViewed') || '{}',
  );
  return objectViewed[objType] && objectViewed[objType][objId];
}

export function updateObjectViews(objId, objType) {
  const objectViewed = JSON.parse(
    localStorage.getItem('_objectViewed') || '{}',
  );
  objectViewed[objType] = objectViewed[objType] || {};
  objectViewed[objType][objId] = 1;
  localStorage.setItem('_objectViewed', JSON.stringify(objectViewed));
}
