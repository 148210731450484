import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';
const DeleteDraw = ({ onClick }) => {
  const ref = createRef();
  const map = useGoogleMap();

  useEffect(() => {
    ref.current.addEventListener('click', onClick);
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
      ref.current,
    );
    return () => {
      if (ref.current && ref.current.remove) {
        ref.current.remove();
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        marginTop: '5px',
      }}
    >
      {/* eslint-disable-next-line react/button-has-type */}
      <div
        style={{
          background: 'white',
          border: 0,
          margin: 0,
          cursor: 'pointer',
          padding: '4px 5px 3px 5px',
          appearance: 'none',
          color: 'rgb(86, 86, 86)',
          fontSize: '11px',
          borderRadius: '2px',
          boxShadow: `rgb(0 0 0 / 30%) 0px 1px 4px -1px`,
          fontWeight: 500,
        }}
      >
        <i
          className="fa fa-trash"
          style={{
            fontSize: '16px',
            lineHeight: '16px',
          }}
        ></i>
      </div>
    </div>
  );
};

DeleteDraw.propTypes = {
  onClick: PropTypes.func,
};
export default DeleteDraw;
