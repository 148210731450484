import React from 'react';
import PropTypes from 'prop-types';
import StatisticsMap from '../StatisticsMap';
import Collapse from '../Collapse';

const CampaignStatisticsMap = ({ campaign, statistics }) => (
  <Collapse title="Heatmap">
    <StatisticsMap id="map" campaign={campaign} statistics={statistics} />
  </Collapse>
);

CampaignStatisticsMap.propTypes = {
  campaign: PropTypes.object,
  statistics: PropTypes.object,
};
export default CampaignStatisticsMap;
