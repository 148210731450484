import { useMutation, useQuery } from 'react-query';
import request from '../utils/request';

export const queryAccess = (uuid) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useQuery(['queryAccess', uuid], () => request(`auth/access/${uuid}`));

export const mutationSignIn = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation((credential) =>
    request('auth/login', { body: credential, method: 'post' }),
  );

export const mutationSendEmailToResetPassword = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation((email) =>
    request('auth/reset', { body: { email }, method: 'post' }),
  );

export const mutationSetNewPassword = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMutation(
    ({
      password,
      password_confirmation: passwordConfirmation,
      userId,
      resetCode,
    }) =>
      request(`auth/reset/${userId}/${resetCode}`, {
        body: { password, password_confirmation: passwordConfirmation },
        method: 'post',
      }),
  );
