import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './assets/css/plugins/dataTables/datatables.min.css';
import './assets/css/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/customize.css';

ReactDOM.render(<App />, document.getElementById('wrapper'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
