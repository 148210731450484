import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import moment from '../moment';

const CampaignItem = ({ campaign }) => {
  const history = useHistory();

  let image = './empty_img.png';
  if (campaign.wrap_images && campaign.wrap_images.length > 0) {
    image = campaign.wrap_images[0].path_string;
  }

  const matchedPercent = campaign.percent_active_user;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="ibox"
      style={{ cursor: 'pointer' }}
      title="View detail campaign"
      onClick={() => history.push(`/campaign/${campaign.id}`)}
    >
      <div className="ibox-title">
        <h5>
          <span className={`label label-${campaign.status_stylish.style}`}>
            {campaign.status_stylish.label}
          </span>
          &nbsp;{campaign.name}
        </h5>
        <div className="ibox-tools">
          <Link to="/">
            <i className="fa fa-area-chart"></i>
          </Link>
        </div>
      </div>
      <div className="ibox-content no-padding border-left-right">
        <div
          className="size_360x240 text-center"
          style={{ backgroundImage: `url(${image})` }}
        >
          <img alt="" src={image} className="" />
        </div>
      </div>
      <div className="ibox-content">
        <div>
          <span>Driver Matching Progress:</span>
          <div className="stat-percent">{matchedPercent}%</div>
          <div className="progress progress-mini">
            <div
              style={{ width: `${matchedPercent}%` }}
              className="progress-bar"
            ></div>
          </div>
        </div>
        <div className="row  m-t-sm">
          <div className="col-sm-4 col-xs-3">
            <div className="font-bold">VEHICLES</div>
            {campaign.number_of_cars} <i className="fa fa-car text-navy"></i>
          </div>
          <div className="col-sm-4 col-xs-4">
            <div className="font-bold">COMMITMENT</div>
            {campaign.number_of_months} weeks
          </div>
          <div className="col-sm-4 col-xs-5 text-right">
            <div className="text-nowrap">
              <i className="fa fa-calendar-check-o"></i>{' '}
              {moment(campaign.beginning_date).format('DD MMM YY')}
            </div>
            <div className="text-nowrap">
              {moment(campaign.end_date).format('DD MMM YY')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CampaignItem.propTypes = {
  campaign: PropTypes.object,
};

export default CampaignItem;
