import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import StatisticsDriverMap from './StatisticsDriverMap';
import StatisticsDriverAreas from './StatisticsDriverAreas';
import WrapImages from './WrapImages';

const DriverModal = ({ driver, campaign, onHide }) => {
  if (!driver) return null;
  const { vehicle } = driver.driver.user;
  const {
    make = {},
    model = {},
    year_of_registration: yearOfRegistration,
    color,
    fuel_type: fuelType,
  } = vehicle;
  return (
    <Modal size="xl" backdrop="static" show={!!driver} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          Driver {driver.driver.driver_id}{' '}
          <small>{driver.driver.user.nickname}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-5">
            <h3>Driver detail</h3>
            <ul className="list-unstyled project-files">
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  Vehicle:{' '}
                  <b>
                    {`${make ? make.name : ''} ${model ? model.model : ''}`}
                  </b>
                </a>
              </li>
              <li className="d-none">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  Year Registration: <b>{yearOfRegistration}</b>
                </a>
              </li>
              <li>
                {color && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a>
                    Color:{' '}
                    <i
                      key={`color_${color.color}`}
                      className="fa fa-car "
                      style={{
                        color: color.color,
                        textShadow: '0px 0px 1px #000',
                        fontSize: '1.4em',
                        padding: '0px 2px',
                      }}
                    ></i>
                  </a>
                )}{' '}
                {fuelType && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a>
                    <span className="badge badge-info">{fuelType.name}</span>
                  </a>
                )}
              </li>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  Wrap types:{' '}
                  {driver.driver.wrap_types.map((wrapType) => (
                    <>
                      <span className="badge badge-secondary" key={wrapType}>
                        {wrapType}
                      </span>{' '}
                    </>
                  ))}
                </a>
              </li>
            </ul>
            <hr />
            <h3>Statistics by areas</h3>
            <StatisticsDriverAreas areas={campaign.areas} driver={driver} />
          </div>
          <div className="col-md-7">
            <StatisticsDriverMap
              campaign={campaign}
              statistics={{
                drivers: [driver],
                areas: driver.areas,
                summary: {
                  total_impressions: driver.total_impressions,
                  total_distance: driver.total_distance,
                  rad: driver.rad,
                },
              }}
            />
            <WrapImages driver={driver} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DriverModal.propTypes = {
  driver: PropTypes.object,
  campaign: PropTypes.object,
  onHide: PropTypes.func,
};

export default DriverModal;
