import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';
import moment from '../moment';
import FormattedNumber from '../Intl/FormattedNumber';
const MapControl = ({
  // tracks,
  control = {},
  isShow,
  setShow,
  onDraw,
}) => {
  const [stats, setStats] = useState({
    total_impressions: 0,
    total_distance: 0,
    prevAct: control.act,
  });

  useEffect(() => {
    const { tracks, currentTrackIndex, speed } = control;
    if (tracks && currentTrackIndex > -1) {
      const bulkTracks = tracks.slice(
        currentTrackIndex,
        currentTrackIndex + speed,
      );
      if (bulkTracks.length > 0) {
        bulkTracks.forEach((track) => {
          if (!track.is_outside) {
            stats.total_impressions += track.impr;
            stats.total_distance += track.dist;
          }
          stats.to = track.r_time;
          setStats({ ...stats });
        });
      }
      /*
      const track = tracks[currentTrackIndex];
      if (track) {
        if (!track.is_outside) {
          stats.total_impressions += track.impr;
          stats.total_distance += track.dist;
        }
        stats.to = track.r_time;
        setStats({ ...stats });
      }
       */
    }
  }, [control.currentTrackIndex]);

  useEffect(() => {
    const { tracks } = control;
    if (control.act === 'reset') {
      stats.total_impressions = 0;
      stats.total_distance = 0;
      stats.from = tracks[0] ? tracks[0].r_time : '-';
      stats.to = tracks[tracks.length - 1]
        ? tracks[tracks.length - 1].r_time
        : '-';
      control.tracks.forEach((track) => {
        if (!track.is_outside) {
          stats.total_impressions += track.impr;
          stats.total_distance += track.dist;
        }
      });
      stats.prevAct = control.act;
      setStats({ ...stats });
    } else if (control.act === 'play' && stats.prevAct === 'reset') {
      stats.total_impressions = 0;
      stats.total_distance = 0;
      stats.from = tracks && tracks[0] ? tracks[0].r_time : '-';
      stats.to = '-';
      stats.prevAct = control.act;
      setStats({ ...stats });
    }
  }, [control.act, control.selectedDrivers]);

  const ref = createRef();
  const map = useGoogleMap();
  const onPlay = () => onDraw('play');
  const onPause = () => onDraw('pause');
  const onSpeed = (stepPositions) => onDraw('speed', stepPositions);
  const onRestart = () => onDraw('reset');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    map.controls[google.maps.ControlPosition.LEFT].push(ref.current);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        margin: '10px 10px',
        backgroundColor: '#FFF',
        border: '2px solid #fff',
        borderRadius: '3px',
        padding: '10px 10px',
        boxShadow: '0 2px 6px rgba(0,0,0,.3)',
      }}
    >
      <div>
        <b>Impressions</b>{' '}
        <h4>
          <FormattedNumber value={stats.total_impressions || 0} />
        </h4>
        <b>Distance (miles)</b>{' '}
        <h4>
          <FormattedNumber value={stats.total_distance || 0} isMile />
        </h4>
      </div>
      <button
        type="button"
        className="btn btn-warning btn-sm"
        style={{ margin: '2px 1px' }}
        title="Re-Draw"
        onClick={onRestart}
      >
        <i className="fa fa-refresh"></i>
      </button>
      {control.act === 'play' && (
        <button
          type="button"
          className="btn btn-default btn-sm"
          style={{ margin: '2px 1px' }}
          onClick={onPause}
        >
          <i className="fa fa-pause"></i>
        </button>
      )}
      {control.act !== 'play' && (
        <button
          type="button"
          className="btn btn-default btn-sm"
          style={{ margin: '2px 1px' }}
          onClick={onPlay}
        >
          <i className="fa fa-play"></i>
        </button>
      )}

      <button
        type="button"
        className="btn btn-default btn-sm"
        style={{ margin: '2px 1px' }}
        title="Speed Up"
        onClick={() => onSpeed(100)}
      >
        <i className="fa fa-angle-up"></i>
      </button>
      <button
        type="button"
        className="btn btn-default btn-sm"
        style={{ margin: '2px 1px' }}
        title="Speed Down"
        onClick={() => onSpeed(-100)}
      >
        <i className="fa fa-angle-down"></i>
      </button>
      <div style={{ backgroundColor: '#efefef', padding: 5, borderRadius: 3 }}>
        <div style={{ marginBottom: 5 }}>
          {stats.from !== '-'
            ? moment(stats.from).format('YYYY-MM-DD HH:mm:ss')
            : stats.from}
        </div>
        {stats.to !== '-'
          ? moment(stats.to).format('YYYY-MM-DD HH:mm:ss')
          : stats.to}
      </div>
      <button
        type="button"
        className="btn btn-success btn-xs m-t-sm btn-block"
        onClick={() => setShow({ interestedAreas: !isShow.interestedAreas })}
      >
        {isShow.interestedAreas
          ? 'Hide interested areas'
          : 'Show interested areas'}
      </button>
      <button
        type="button"
        className="btn btn-success btn-xs m-t-sm btn-block"
        onClick={() => setShow({ heatmap: !isShow.heatmap })}
      >
        {isShow.heatmap ? 'Hide heatmap' : 'Show heatmap'}
      </button>
      <button
        type="button"
        className="btn btn-success btn-xs m-t-sm btn-block"
        onClick={() => setShow({ routes: !isShow.routes })}
      >
        {isShow.routes ? 'Hide routes' : 'Show routes'}
      </button>
    </div>
  );
};

MapControl.propTypes = {
  control: PropTypes.object,
  isShow: PropTypes.object,
  setShow: PropTypes.func,
  onDraw: PropTypes.func,
};

export default MapControl;
