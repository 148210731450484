import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MapSelectedDrivers from './MapSelectedDrivers';
import Heatmap from './Heatmap';
import MapControl from './MapControl';
import Routes from './Routes';
import AreaLayer from './AreaLayer';
import ExtraAreas from './ExtraAreas';
import StreetView from './StreetView';

const INIT_STATE_CONTROL = {
  act: 'reset', // check by this parameter
  speed: 5,
  currentTrackIndex: -1,
  selectedDrivers: [],
  drawInterval: null,
};

const MapContent = ({
  tracks,
  routes,
  driverMarkers,
  campaign,
  statistics,
  extraAreas,
}) => {
  const [control, setControl] = useState({
    ...INIT_STATE_CONTROL,
    tracks,
  });
  const [isShow, setIsShow] = useState({
    interestedAreas: true,
    heatmap: false,
    routes: true,
  });

  const [streetView, setStreetView] = useState({
    visible: false,
    position: {},
  });

  useEffect(() => () => setControl(INIT_STATE_CONTROL), []);

  /* TODO: implement StreetView */
  // eslint-disable-next-line no-unused-vars
  const onShowStreetView = (position) => {
    setStreetView({
      visible: true,
      position,
    });
  };

  const onSelectDrivers = (driverIds) => {
    // stop interval
    if (control.drawInterval) clearInterval(control.drawInterval);
    // get tracks
    const showTracks = tracks.filter(
      (track) => driverIds.length < 1 || driverIds.includes(track.driver_id),
    );
    // reset control
    setControl({
      ...INIT_STATE_CONTROL,
      selectedDrivers: driverIds,
      tracks: showTracks,
    });
  };

  const drawMap = () => {
    control.currentTrackIndex += control.speed;
    if (control.tracks && control.tracks[control.currentTrackIndex]) {
      setControl({ ...control });
    } else if (control.drawInterval) {
      // finish
      clearInterval(control.drawInterval);
      setControl({
        ...control,
        act: 'reset',
        currentTrackIndex: -1,
        drawInterval: null,
      });
    }
  };

  const onDraw = (act, stepPositions = 0) => {
    control.speed += stepPositions;
    control.speed = control.speed <= 5 ? 5 : control.speed;
    control.speed = control.speed > 1000 ? 1000 : control.speed;
    // eslint-disable-next-line default-case
    switch (act) {
      case 'play':
        control.act = act;
        if (!control.drawInterval)
          control.drawInterval = setInterval(drawMap, 100);
        setControl({ ...control });
        break;
      case 'speed':
        if (control.drawInterval) {
          clearInterval(control.drawInterval);
          control.drawInterval = setInterval(drawMap, 100);
        }
        setControl({ ...control });
        break;
      case 'pause':
        control.act = act;
        if (control.drawInterval) {
          clearInterval(control.drawInterval);
          control.drawInterval = null;
        }
        setControl({ ...control });
        break;
      case 'reset':
        control.act = act;
        if (control.drawInterval) {
          clearInterval(control.drawInterval);
        }
        setControl({
          ...control,
          ...INIT_STATE_CONTROL,
        });
    }
  };

  return (
    <>
      {campaign.areas
        .filter((area) => area.geometry && area.geometry.outerBoundaryIs)
        .map((area) => (
          <AreaLayer
            key={`area_${area.id}`}
            isShow={isShow.interestedAreas}
            area={area}
            stats={(statistics.areas || []).find(
              // eslint-disable-next-line eqeqeq
              (areaStat) => areaStat.area_id == area.id,
            )}
          />
        ))}
      <MapControl
        control={control}
        onDraw={onDraw}
        isShow={isShow}
        setShow={(showing) => {
          setIsShow({
            ...isShow,
            ...showing,
          });
        }}
      />
      <Heatmap control={control} isShow={isShow.heatmap} />
      {extraAreas && <ExtraAreas areas={extraAreas} />}
      <Routes
        control={control}
        routes={routes}
        driverMarkers={driverMarkers}
        isShow={isShow.routes}
      />
      <MapSelectedDrivers
        control={control}
        drivers={statistics.drivers}
        onSelectDrivers={onSelectDrivers}
      />
      <StreetView
        onClose={() => setStreetView({ visible: false, position: {} })}
        position={streetView.position}
        visible={streetView.visible}
      />
    </>
  );
};

MapContent.propTypes = {
  tracks: PropTypes.array,
  extraAreas: PropTypes.any,
  routes: PropTypes.object,
  driverMarkers: PropTypes.object,
  campaign: PropTypes.object,
  statistics: PropTypes.object,
};
export default MapContent;
