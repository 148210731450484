import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';
const Routes = ({ control, isShow, routes, driverMarkers }) => {
  const map = useGoogleMap();
  const [state, setState] = useState({
    prevAct: control.act,
  });
  useEffect(() => {
    // visible routes of selected drivers
    Object.keys(routes).forEach((routeId) => {
      const visible =
        isShow &&
        (control.selectedDrivers.length < 1 ||
          control.selectedDrivers.includes(routes[routeId].driverId));
      routes[routeId].overlay.setVisible(visible);
    });
  }, [control.selectedDrivers, isShow]);

  useEffect(() => {
    const resetRoutes = () =>
      Object.keys(routes).forEach((routeId) => {
        const route = routes[routeId];
        const marker = driverMarkers[route.driverId];
        const path = route.overlay.getPath();
        path.clear();
        marker.setMap(null);
      });

    if (control.act === 'reset') {
      resetRoutes();

      const { tracks = [] } = control;
      tracks.forEach((track) => {
        const route = routes[track.routeId];
        const path = route.overlay.getPath();
        path.push(track.position);
      });
    }

    if (control.act === 'play') {
      if (state.prevAct === 'reset') {
        resetRoutes();
      }
      const bulkTracks = control.tracks.slice(
        control.currentTrackIndex,
        control.currentTrackIndex + control.speed,
      );
      if (bulkTracks.length > 0) {
        bulkTracks.forEach((track) => {
          const route = routes[track.routeId];
          const marker = driverMarkers[track.driver_id];
          const path = route.overlay.getPath();
          path.push(track.position);
          if (isShow) marker.setMap(map);
          else marker.setMap(null);
          marker.setPosition(track.position);
        });
      }
    }

    setState({
      ...state,
      prevAct: control.act,
    });
  }, [control.act, control.currentTrackIndex, control.selectedDrivers, isShow]);

  return null;
};

Routes.propTypes = {
  isShow: PropTypes.bool,
  control: PropTypes.object,
  routes: PropTypes.object,
  driverMarkers: PropTypes.object,
};
export default Routes;
