import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSortBy, useTable } from 'react-table';
import Collapse from '../../../Collapse';

const columns = [
  {
    Header: 'Status',
    id: 'status',
    accessor: (row) => row.campaigndriver.status_stylish.label,
    Cell: (cell) => (
      <span
        className={`label label-${cell.row.original.campaigndriver.status_stylish.style}`}
      >
        {cell.row.original.campaigndriver.status_stylish.label}
      </span>
    ),
  },
  {
    Header: 'Driver',
    id: 'driver',
    accessor: (row) => (
      <>
        {`${row.campaigndriver.user.fullname} `}
        {row.description && (
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={
              <Popover id="popover-basic">
                <Popover.Title as="h4">Generic error</Popover.Title>
                <Popover.Content style={{ whiteSpace: 'pre-line' }}>
                  {row.description}
                </Popover.Content>
              </Popover>
            }
          >
            <i
              className="text-warning fa fa-info-circle"
              style={{ cursor: 'pointer', fontSize: 15 }}
            ></i>
          </OverlayTrigger>
        )}
      </>
    ),
  },
  {
    Header: 'Vehicle',
    id: 'vehicle',
    accessor: (row) =>
      `${row.campaigndriver.user.profile.car_make} ${row.campaigndriver.user.profile.car_model}`,
  },
  {
    Header: 'Wrap types',
    id: 'wrap_types',
    disableSortBy: true,
    accessor: (row) =>
      row.campaigndriver.wrap_types.map((wrapType) => (
        <span
          className="badge badge-secondary"
          style={{ marginRight: 2 }}
          key={wrapType}
        >
          {wrapType}
        </span>
      )),
  },
];

const DetailSawrmDrivers = ({ drivers }) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns: useMemo(() => columns, []),
        data: drivers,
      },
      useSortBy,
    );

  return (
    <Collapse title="Drivers">
      <div className="table-responsive">
        <Table striped {...getTableProps()} className="dataTable">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`text-nowrap ${
                    // eslint-disable-next-line no-nested-ternary
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorting_desc'
                        : 'sorting_asc'
                      : column.disableSortBy || 'sorting'
                  }`}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Collapse>
  );
};

DetailSawrmDrivers.propTypes = {
  drivers: PropTypes.array,
};
export default DetailSawrmDrivers;
