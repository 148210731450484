import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { Link } from 'react-router-dom';
import moment from '../../moment';
import DrawMap from '../../DrawMap';
import { SWARM_EVENT_STATUS } from '../../../constains/variables';

const SwarmModal = ({ campaign, event, onClose }) => {
  const { showNewMessages } = useIntercom();

  const getCenter = () => {
    let bounds;
    const { criteria } = event.swarm_area;
    switch (event.swarm_area.type) {
      case 'distance':
      case 'circle':
      case 'postcode':
        return new window.google.maps.LatLng(criteria.lat, criteria.lon);
      case 'rectangle':
      case 'bounding_box':
        bounds = new window.google.maps.LatLngBounds();
        bounds.extend(
          new window.google.maps.LatLng(
            criteria.top_left.lat,
            criteria.top_left.lon,
          ),
        );
        bounds.extend(
          new window.google.maps.LatLng(
            criteria.bottom_right.lat,
            criteria.bottom_right.lon,
          ),
        );
        return bounds.getCenter();
      default:
        bounds = new window.google.maps.LatLngBounds();
        criteria.points.forEach((point) => {
          bounds.extend(new window.google.maps.LatLng(point[0], point[1]));
        });
        return bounds.getCenter();
    }
  };

  if (event) {
    return (
      <Modal size="xl" show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${campaign.name}. ${event.location_name} Domination Event`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-5">
              <p>{event.number_of_cars} cars</p>
              <p>
                Start: {moment(event.swarm_date).format('Do MMM YYYY')}
                {', '}
                {moment(event.start_time, 'HH:mm:ss').format('hh:mm A')}
              </p>
              <p>Duration: {event.duration} minutes</p>
              <p>
                <span className="text-info">
                  {event.status === SWARM_EVENT_STATUS.PLANNING &&
                    'The domination report will be ready soon.'}
                  {event.status !== SWARM_EVENT_STATUS.PLANNING &&
                    'You can access live data once your event starts.'}
                </span>
                <br />
                {`Feel free to `}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    showNewMessages(
                      'Hello, I would like to know about my planning event.',
                    );
                  }}
                >
                  contact us
                </Link>{' '}
                if you have questions or changes.
              </p>
            </div>
            <div className="col-sm-12 col-md-7">
              <DrawMap
                id={`confirmedSwarm_${event.id}`}
                initDrawAreas={[event.swarm_area]}
                areas={campaign.areas}
                drawingControl={false}
                mapContainerStyle={{ width: '100%', height: 300 }}
                onLoad={(map) => {
                  map.setCenter(getCenter());
                  map.setZoom(8);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return null;
};

SwarmModal.propTypes = {
  campaign: PropTypes.object,
  event: PropTypes.object,
  onClose: PropTypes.func,
};

export default SwarmModal;
