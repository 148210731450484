import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { mutationSetNewPassword } from '../apis/AuthApi';
import Field from '../components/ExForm/Field';

function SetPasswordPage() {
  const params = useParams();

  const mutation = mutationSetNewPassword();
  const { isLoading, isError, error, isSuccess, data } = mutation;
  return (
    <div className="animated fadeInRight">
      <h2 className="text-white">Reset password</h2>
      <div className="ibox-content m-t text-left">
        <Formik
          initialValues={{ password: '', password2: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.password) {
              errors.password = 'Password is required';
            }
            if (values.password !== values.password2) {
              errors.password2 = 'Password is not matched';
            }
            return errors;
          }}
          onSubmit={(values) =>
            mutation.mutate({
              password: values.password,
              password_confirmation: values.password2,
              ...params,
            })
          }
        >
          {(form) => (
            <Form onSubmit={form.handleSubmit}>
              {isError && (
                <div className="alert alert-danger text-left">{error}</div>
              )}
              {isSuccess && data && (
                <div className="alert alert-success text-left">{data}</div>
              )}
              <p>Enter your new password</p>
              <Field
                type="password"
                name="password"
                placeholder="New password"
                prependIcon="glyphicon glyphicon-lock"
                form={form}
              />
              <Field
                type="password"
                name="password2"
                placeholder="Password confirmation"
                prependIcon="glyphicon glyphicon-lock"
                form={form}
              />
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-primary block full-width m-b"
              >
                Save {isLoading && <i className="fa fa-spinner fa-spin"></i>}
              </button>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <Link to="/auth/login">
            <i className="fa fa-sign-in"></i> I remember my password
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SetPasswordPage;
