import React from 'react';
import { useIntercom } from 'react-use-intercom';
import Collapse from '../../Collapse';

const Pending = () => {
  const { showNewMessages } = useIntercom();

  return (
    <Collapse className="animated fadeIn ">
      <h2 className="m-b">Request pending</h2>
      Your request has been submitted.
      <div className="progress">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-success "
          role="progressbar"
          style={{ width: '33%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-warning "
          role="progressbar"
          style={{ width: '33%' }}
        />
      </div>
      <h3 className="pt-2">Thanks for wanting to offset your carbon </h3>
      <p>
        We will be in touch within 96 hours to confirm all the details. If you
        have any questions, please contact our team.
      </p>
      <button
        type="button"
        onClick={() =>
          showNewMessages(
            'Hello, I would like to know about my carbon offset request process.',
          )
        }
        className="btn btn-primary btn-sm m-r"
      >
        Contact The Team
      </button>
    </Collapse>
  );
};

Pending.propTypes = {};

export default Pending;
