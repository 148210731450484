import React from 'react';
import Collapse from '../../Collapse';

const Introduce = () => (
  <Collapse>
    <h2 className="m-b">What is Carbon Offsetting? </h2>
    <div className="row align-items-center">
      <div className="col-xl-2 col-lg-3 col-sm-3 text-center">
        <img
          src="/earthly_logo.png"
          className="w-100"
          style={{ maxWidth: 120 }}
          alt=""
        />
      </div>
      <div className="col-xl-6 col-lg-9 col-sm-9">
        <p>Save the planet while winning new customers.</p>
        <ul>
          <li> Offset your carbon emissions with a few clicks</li>
          <li> Restore nature and progress towards Net Zero</li>
          <li>Support local communities around the world</li>
        </ul>
        <p>
          <b>Don’t forget</b>: we offer EV and hybrid fleets as well
          biodegradable car wraps
        </p>
      </div>
      <div className="col-xl-4 text-right">
        <img src="/earthly_bg.png" className="w-100" alt="" />
      </div>
    </div>
  </Collapse>
);

export default Introduce;
