import React, { useEffect, useState } from 'react';
import { mutationSwarmEvent } from '../apis/CampaignApi';
import { useLoading } from '../providers/LoadingProvider';
import { useCampaign } from '../providers/CampaignProvider';
import { SWARM_EVENT_STATUS } from '../constains/variables';

import CampaignLayout from '../components/Campaign/CampaignLayout';
import Start from '../components/Campaign/SwarmEvent/Start';
import Introduce from '../components/Campaign/SwarmEvent/Introduce';
import Request from '../components/Campaign/SwarmEvent/Request';
import SwarmEvents from '../components/Campaign/SwarmEvent/SwarmEvents';
import Pending from '../components/Campaign/SwarmEvent/Pending';
import Confirmed from '../components/Campaign/SwarmEvent/Confirmed';
import { objectReadyViewed } from '../utils/helpers';
import Live from '../components/Campaign/SwarmEvent/Live';

function CampaignSwarmEventsPage() {
  const { campaign, swarmEvents } = useCampaign();
  const [step, setStep] = useState(1);
  const mutation = mutationSwarmEvent();

  const { toggleLoading } = useLoading(); // loading
  useEffect(() => {
    toggleLoading({ ...mutation });
  }, [mutation.isLoading]);

  const createEventCom = () => {
    const pendingEvent = swarmEvents.find(
      (item) => item.status === SWARM_EVENT_STATUS.PENDING,
    );
    if (pendingEvent) return <Pending event={pendingEvent} />;

    const confirmedEvent = swarmEvents.find(
      (item) =>
        item.status === SWARM_EVENT_STATUS.CONFIRMED &&
        !objectReadyViewed(item.id, 'swarm_event'),
    );
    if (confirmedEvent) return <Confirmed event={confirmedEvent} />;

    switch (step) {
      case 1:
        return <Start swarmEvents={swarmEvents} onStart={() => setStep(2)} />;
      case 2:
        return (
          <Request
            campaign={campaign}
            onCancel={() => setStep(1)}
            onRequest={(values) => mutation.mutate(values)}
          />
        );
      default:
        return null;
    }
  };

  const liveEvent = swarmEvents.find(
    (item) => item.status === SWARM_EVENT_STATUS.LIVE,
  );

  return (
    <CampaignLayout>
      {liveEvent && <Live event={liveEvent} />}
      {createEventCom()}
      <SwarmEvents swarmEvents={swarmEvents} />
      <Introduce />
    </CampaignLayout>
  );
}

export default CampaignSwarmEventsPage;
