import React from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import Gmap from '../components/GMap';
import { queryCalibration } from '../apis/TestApi';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Route = ({ data }) => {
  const map = useGoogleMap();
  const bounds = new window.google.maps.LatLngBounds();
  const polyline = new window.google.maps.Polyline({
    strokeColor: 'red',
    strokeWeight: 2,
    map,
  });
  const path = polyline.getPath();
  data.forEach((item) => {
    const { coordinates } = item.geo_point;
    path.push(new window.google.maps.LatLng(coordinates[1], coordinates[0]));
    bounds.extend(
      new window.google.maps.LatLng(coordinates[1], coordinates[0]),
    );
  });
  if (data.length > 0) {
    let { coordinates } = data[0].geo_point;
    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      position: new window.google.maps.LatLng(coordinates[1], coordinates[0]),
      label: 'S',
      map,
    });
    coordinates = data[data.length - 1].geo_point.coordinates;
    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      position: new window.google.maps.LatLng(coordinates[1], coordinates[0]),
      label: 'F',
      map,
    });
  }
  map.setCenter(bounds.getCenter());
  map.setZoom(12);
  return '';
};

function MapPage() {
  const query = useQuery();
  const { isSuccess, data = [] } = queryCalibration({
    userId: query.get('userId'),
    from: query.get('from'),
    to: query.get('to'),
  });
  const distance = data.reduce((acc, item) => acc + item.distance_r, 0);
  if (isSuccess)
    return (
      <div
        style={{ background: '#ffffff', minHeight: '100%', minWidth: '100%' }}
      >
        <div style={{ width: '100%' }}>
          <Gmap id="test_map">
            <Route data={data} />
          </Gmap>
        </div>
        <br />
        <div>Count points = {data.length}</div>
        {data.length > 0 && (
          <div>
            <div>
              Start point = {data[0]['@timestamp']} | LatLng{' '}
              {data[0].geo_point.coordinates[1]},
              {data[0].geo_point.coordinates[0]}
            </div>
            <div>
              End point = {data[data.length - 1]['@timestamp']} | LatLng{' '}
              {data[data.length - 1].geo_point.coordinates[1]},
              {data[data.length - 1].geo_point.coordinates[0]}
            </div>
          </div>
        )}
        <div>Total distance_r = {distance}</div>
      </div>
    );
  return '';
}

export default MapPage;
