import {
  MAP_INIT_MAX_DISTANCE_POINTS,
  MAP_STEP_MAX_DISTANCE_POINTS,
} from '../../constains/variables';
import { convertDistanceUnit } from '../../utils/helpers';

export const convertArrayToGeoArray = (arr) =>
  arr.map((a) => ({ lat: a[1], lng: a[0] }));

export const convertToLatLon = (point) =>
  new window.google.maps.LatLng(point.lat, point.lon || point.lng);

export const computeDistanceBetween = (point1, point2, weight = 1) =>
  window.google.maps.geometry.spherical.computeDistanceBetween(point1, point2) >
  MAP_INIT_MAX_DISTANCE_POINTS + MAP_STEP_MAX_DISTANCE_POINTS * weight;

export const boundCircle = (overlay, convertUnit = 'meter2mile') => {
  // eslint-disable-next-line default-case
  switch (overlay.type) {
    case 'distance':
    case 'circle':
    case 'postcode':
      return {
        point: overlay.overlay.getCenter(),
        radius: convertDistanceUnit(overlay.overlay.getRadius(), convertUnit),
      };
    /*
    case 'rectangle':
    case 'bounding_box':
      bounds = overlay.overlay.getBounds();
      return {
        // point: bounds.getCenter(),
        radius: window.google.maps.geometry.spherical.computeArea(bounds),
      };
    default:
      // bounds = overlay.overlay.getBounds();
      return {
        // point: bounds.getCenter(),
        radius: window.google.maps.geometry.spherical.computeArea(
          overlay.overlay.getPath(),
        ),
      };
     */
  }
  return null;
};
export const getCenterOfAreas = (areas) => {
  const bounds = new window.google.maps.LatLngBounds();
  areas.forEach((area) => {
    if (area.geometry && area.geometry.outerBoundaryIs) {
      area.geometry.outerBoundaryIs.forEach((points) => {
        points.forEach((point) => {
          bounds.extend(new window.google.maps.LatLng(point[1], point[0]));
        });
      });
    }
  });
  if (!bounds.isEmpty()) {
    return bounds.getCenter();
  }
  return { lat: 52.509865, lng: -0.118092 };
};

export const parseArea = (area) => {
  const newArea = {};
  let center;
  let bounds;
  switch (area.type) {
    case 'rectangle':
    case 'bounding_box':
      bounds = area.overlay.getBounds().toJSON();
      newArea.type = 'bounding_box';
      newArea.criteria = {
        top_left: { lat: bounds.north, lon: bounds.west },
        bottom_right: { lat: bounds.south, lon: bounds.east },
      };
      break;
    case 'circle':
    case 'distance':
      center = area.overlay.getCenter();
      newArea.type = 'distance';
      newArea.criteria = { lat: center.lat(), lon: center.lng() };
      newArea.distance = area.overlay.getRadius(); // in meters
      break;
    case 'postcode':
      center = area.overlay.getCenter();
      newArea.criteria = { lat: center.lat(), lon: center.lng() };
      newArea.distance = area.overlay.getRadius(); // in meters
      newArea.data.code = area.data.code || null;
      break;
    default:
      // polygon
      newArea.criteria = { points: [] };
      area.overlay.getPath().forEach((path) => {
        newArea.criteria.points.push([path.lat(), path.lng()]);
      });
      break;
  }
  return newArea;
};
