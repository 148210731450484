import { useQuery } from 'react-query';

// name of queries
export const QUERY_CALIBRATION = 'apis/TestApi/QUERY_CALIBRATION';

export const queryCalibration = ({ userId, from, to }) => {
  const queryParam = [];
  if (from) queryParam.push(`from=${from}`);
  if (to) queryParam.push(`to=${to}`);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    QUERY_CALIBRATION,
    () =>
      fetch(
        `http://23.251.135.38:3030/driver_points/${userId}?${queryParam.join(
          '&',
        )}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'cfba0afe-e235-4927-b1ce-c5468d1d3166',
          },
        },
      )
        .then((res) => res.json())
        .then((items) =>
          items.sort((a, b) => {
            if (a['@timestamp'] > b['@timestamp']) return 1;
            if (a['@timestamp'] < b['@timestamp']) return -1;
            return 0;
          }),
        ),
    {
      staleTime: 0,
    },
  );
};
