/**
 *
 * Input
 *
 */

import React, { useState } from 'react';
import Timekeeper from 'react-timekeeper';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const TimeKeeper = ({ name, label, form, ...rest }) => {
  const [toggle, setToggle] = useState(false);
  const error = getIn(form.errors, name);
  const touched = getIn(form.touched, name);

  return (
    <div className="form-group">
      {label && <label>{label}</label>}
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="fa fa-clock-o"></i>
          </span>
        </div>
        <input
          {...rest}
          type="text"
          name={name}
          value={form.values[name]}
          onBlur={form.handleBlur}
          onChange={() => {}}
          onClick={() => setToggle(!toggle)}
          className={`form-control text-center ${
            error && touched ? 'is-invalid' : ''
          }`}
        />
      </div>

      {error && touched && <div className="invalid-feedback">{error}</div>}
      {toggle && (
        <Timekeeper
          hour24Mode
          time={form.values[name]}
          onChange={({ formatted24 }) => form.setFieldValue(name, formatted24)}
          onDoneClick={() => setToggle(false)}
        />
      )}
    </div>
  );
};

TimeKeeper.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  form: PropTypes.object,
  touchSpinOptions: PropTypes.object,
};

export default TimeKeeper;
