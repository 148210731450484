import { useEffect } from 'react';
import { useCampaign } from '../providers/CampaignProvider';
import { useLoading } from '../providers/LoadingProvider';
import {
  queryCampaign,
  queryCampaignCarbonOffset,
  queryCampaignDigital,
  querySwarmEvents,
} from '../apis/CampaignApi';

export const usePrefetchCampaign = (campaignId) => {
  const {
    campaign,
    setCampaign,
    digital,
    setDigital,
    carbonOffset,
    setCarbonOffset,
    swarmEvents,
    setSwarmEvents,
  } = useCampaign();
  const { toggleLoading } = useLoading();
  const fetchCampaign = queryCampaign(campaignId);
  const fetchDigital = queryCampaignDigital(campaignId);
  const fetchCarbonOffset = queryCampaignCarbonOffset(campaignId);
  const fetchSwarmEvents = querySwarmEvents(campaignId);

  const isLoading =
    fetchCampaign.isLoading ||
    fetchDigital.isLoading ||
    fetchCarbonOffset.isLoading ||
    fetchSwarmEvents.isLoading;
  const isSuccess =
    fetchCampaign.isSuccess &&
    fetchDigital.isSuccess &&
    fetchCarbonOffset.isSuccess &&
    fetchSwarmEvents.isSuccess;
  const isFetched =
    fetchCampaign.isFetched &&
    fetchDigital.isFetched &&
    fetchCarbonOffset.isFetched &&
    fetchSwarmEvents.isFetched;
  const error =
    fetchCampaign.error ||
    fetchDigital.error ||
    fetchCarbonOffset.error ||
    fetchSwarmEvents.error;
  useEffect(() => {
    if (isSuccess) {
      setCampaign(fetchCampaign.data);
      setDigital(fetchDigital.data);
      setCarbonOffset(fetchCarbonOffset.data);
      setSwarmEvents(fetchSwarmEvents.data);
    }
  }, [
    isSuccess,
    fetchCampaign.data,
    fetchDigital.data,
    fetchCarbonOffset.data,
    fetchSwarmEvents.data,
  ]);

  useEffect(() => {
    // if (isFetched) return;
    if (campaignId) toggleLoading({ isLoading, error });
  }, [isLoading, isFetched]);

  return { campaign, digital, carbonOffset, swarmEvents };
};
