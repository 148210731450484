import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '../../Collapse';

const Start = ({ swarmEvents, onStart }) => {
  let text = 'Want another Domination?';
  let h2 = 'Create new Domination now';
  let btn = 'Create Domination';
  if (!swarmEvents || swarmEvents.length < 1) {
    text = 'You have no Domination Events pending';
    h2 = 'Want to set one up?';
    btn = 'Set Up Domination Event';
  }
  return (
    <Collapse className="animated fadeIn">
      <p>{text}</p>
      <h2>{h2}</h2>
      <button
        type="button"
        onClick={onStart}
        className="btn btn-primary btn-sm"
      >
        {btn}
      </button>
    </Collapse>
  );
};

Start.propTypes = {
  swarmEvents: PropTypes.array,
  onStart: PropTypes.func,
};

export default Start;
