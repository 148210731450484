import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfoWindow, Polygon } from '@react-google-maps/api';
import { convertArrayToGeoArray } from '../GMap/helpers';
import FormattedNumber from '../Intl/FormattedNumber';

const AreaLayer = ({ isShow, area, stats = {} }) => {
  const [state, setState] = useState({
    toggleInfo: false,
    mousePosition: null,
  });

  const polygon = (path, index) => (
    <Polygon
      key={index.toString()}
      visible={isShow}
      path={convertArrayToGeoArray(path)}
      options={{
        zIndex: -1,
        geodesic: true,
        strokeColor: '#399eff',
        strokeOpacity: area.is_primary ? 0.8 : 0.5,
        strokeWeight: 1.5,
        fillColor: '#99c4ff',
        fillOpacity: area.is_primary ? 0.4 : 0.1,
      }}
      onClick={(evt) => {
        setState({
          toggleInfo: true,
          mousePosition: evt.latLng,
        });
      }}
    />
  );

  const polygons = [];
  if (area.geometry && area.geometry.outerBoundaryIs) {
    area.geometry.outerBoundaryIs.forEach((path, index) => {
      if (path[0].length > 2) {
        path.map((subPath, idx) =>
          polygons.push(polygon(subPath, `${idx}_${index}`)),
        );
      } else {
        polygons.push(polygon(path, index.toString()));
      }
    });
  }

  if (polygons.length > 0) {
    return (
      <>
        {polygons}
        {state.toggleInfo && (
          <InfoWindow
            onCloseClick={() =>
              setState({
                ...state,
                toggleInfo: false,
              })
            }
            position={state.mousePosition}
          >
            <div>
              <h4>{area.name}</h4>
              {Object.keys(stats).length > 0 && (
                <ul>
                  <li>
                    <b>Impressions</b>:{' '}
                    <FormattedNumber value={stats.total_impressions || 0} />
                  </li>
                  <li>
                    <b>Distance</b>:{' '}
                    <FormattedNumber value={stats.total_distance || 0} isMile />{' '}
                    (miles)
                  </li>
                  <li className="d-none">
                    <b>Time Driving</b>:{' '}
                    <FormattedNumber value={stats.total_time || 0} isHour />{' '}
                    (hours)
                  </li>
                  <li className="d-none">
                    <b>Time Parked</b>:{' '}
                    <FormattedNumber
                      value={stats.total_time_parked || 0}
                      isHour
                    />
                    {' (hours)'}
                  </li>
                </ul>
              )}
            </div>
          </InfoWindow>
        )}
      </>
    );
  }
  return null;
};
AreaLayer.propTypes = {
  isShow: PropTypes.bool,
  area: PropTypes.object,
  stats: PropTypes.object,
};
export default AreaLayer;
