import React, { useContext, createContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntercom } from 'react-use-intercom';
import {
  getCurrentUserFromStorage,
  getSignedInFromStorage,
  isRemember,
  resetDeviceStorage,
  setCurrentUserToStorage,
} from '../utils/auth';
import { OFFICIAL_PAGE } from '../constains/variables';
const INIT_AUTH_CONTEXT = {
  signedIn: false,
  currentUser: null,
  updateCurrentUser: () => {
    throw new Error('Must override!');
  },
  signOut: () => {
    throw new Error('Must override!');
  },
};

export const AuthContext = createContext(INIT_AUTH_CONTEXT);

const AuthProvider = (props) => {
  const [signedIn, setSignedIn] = useState(getSignedInFromStorage());
  const [currentUser, setCurrentUser] = useState(getCurrentUserFromStorage());
  const { boot, shutdown } = useIntercom();

  if (signedIn) {
    boot({
      hideDefaultLauncher: false,
      email: currentUser.email,
      name: currentUser.name,
    });
  } else {
    shutdown();
  }

  const updateCurrentUser = (user, remember) => {
    setSignedIn(true);
    setCurrentUser(user);
    setCurrentUserToStorage(
      user,
      remember === undefined ? isRemember() : remember,
    );
  };

  const signOut = () => {
    resetDeviceStorage();
    window.location.href = `${OFFICIAL_PAGE}logout`;
    // setSignedIn(false);
    // setCurrentUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, signedIn, updateCurrentUser, signOut }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
