import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
export const CampaignContext = createContext({
  campaign: null,
  digital: null,
  carbonOffset: null,
  swarmEvents: [],
  setCampaign: () => {},
  setDigital: () => {},
  setCarbonOffset: () => {},
  setSwarmEvents: () => {},
});

export const useCampaign = () => useContext(CampaignContext);

const CampaignProvider = (props) => {
  const [campaign, setCampaign] = useState(null);
  const [digital, setDigital] = useState(null);
  const [carbonOffset, setCarbonOffset] = useState(null);
  const [swarmEvents, setSwarmEvents] = useState([]);

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        setCampaign,
        digital,
        setDigital,
        carbonOffset,
        setCarbonOffset,
        swarmEvents,
        setSwarmEvents,
      }}
    >
      {props.children}
    </CampaignContext.Provider>
  );
};

CampaignProvider.propTypes = {
  children: PropTypes.node,
};

export default CampaignProvider;
