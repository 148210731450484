/**
 *
 * Input
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const NumberInput = ({ name, label, form, step = 1, min = 1, ...rest }) => {
  const error = getIn(form.errors, name);

  const handleChange = (value) => {
    if (value !== '') {
      const parseValue = parseFloat(value) || min;
      form.setFieldValue(name, parseValue);
    } else {
      form.setFieldValue(name, '');
    }
  };

  const handleClickChange = (numberChange) => {
    const value =
      (parseFloat(form.values[name]) || 0) + parseFloat(numberChange);
    handleChange(value < min ? min : value);
  };

  return (
    <div className="form-group">
      {label && <label>{label}</label>}
      <div className="input-group">
        <div className="input-group-prepend">
          <button
            className={`btn btn-${error ? 'outline-danger' : 'default'}`}
            type="button"
            onClick={() => handleClickChange(0 - step)}
          >
            -
          </button>
        </div>
        <input
          {...rest}
          type="text"
          name={name}
          value={form.values[name]}
          onChange={(evt) => handleChange(evt.currentTarget.value)}
          onBlur={form.handleBlur}
          className={`form-control text-center ${error ? 'is-invalid' : ''}`}
        />
        <div className="input-group-append">
          <button
            className={`btn btn-${error ? 'outline-danger' : 'default'}`}
            type="button"
            onClick={() => handleClickChange(step)}
          >
            +
          </button>
        </div>
      </div>
      {error && (
        <div className="invalid-feedback" style={{ display: 'inherit' }}>
          {error}
        </div>
      )}
    </div>
  );
};

NumberInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  form: PropTypes.object,
  step: PropTypes.number,
  min: PropTypes.number,
};

export default NumberInput;
