import React from 'react';
import PropTypes from 'prop-types';
import { useIntercom } from 'react-use-intercom';
import moment from '../../moment';
import Collapse from '../../Collapse';
const Confirmed = ({ campaign }) => {
  const { showNewMessages } = useIntercom();
  const days = moment(campaign.beginning_date).diff(moment(), 'days');
  return (
    <Collapse className="animated fadeIn ">
      <h2 className="m-b">We have accepted your Digital campaign</h2>
      {days > 0 && `Your campaign will start in ${days} days.`}
      <div className="progress">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-success "
          role="progressbar"
          style={{ width: '25%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-warning "
          role="progressbar"
          style={{ width: '25%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-danger "
          role="progressbar"
          style={{ width: '25%' }}
        />
      </div>
      <h3 className="pt-2">Your campaign is set</h3>
      <p>
        We have confirmed everything and your digital campaign will start soon!
      </p>
      <button
        type="button"
        onClick={() =>
          showNewMessages(
            'Hello, I would like to know about my digital request process.',
          )
        }
        className="btn btn-primary btn-sm m-r"
      >
        Contact The Team
      </button>
      {/* digital && digital.report && (
        <>
          <hr />
          <iframe
            src={digital.report}
            frameBorder="0"
            style={{ border: 0, width: '100%', minHeight: 450 }}
            allowFullScreen
          />
        </>
      ) */}
    </Collapse>
  );
};

Confirmed.propTypes = {
  campaign: PropTypes.object,
};

export default Confirmed;
