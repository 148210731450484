import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from '../../providers/AuthProvider';
import UserMenu from './UserMenu';
import { minimalizeMenu } from '../../utils/inspinia';

// eslint-disable-next-line react/prop-types
const ToggleUser = React.forwardRef(({ onClick }, ref) => {
  const { currentUser } = useAuth();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid
    <a ref={ref} onClick={onClick}>
      <div className="d-flex align-items-center">
        <div
          className="img-circle"
          style={{
            margin: '-5px 5px -5px 0px',
            height: '30px',
            width: '30px',
            display: 'inline-block',
            background: `url(${currentUser.avatar})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
        <span className=" d-none d-sm-inline px-1">{currentUser.name}</span>
        <span className="caret"></span>
      </div>
    </a>
  );
});

// eslint-disable-next-line react/prop-types
const TopMenu = () => {
  useEffect(() => {
    minimalizeMenu();
  }, []);
  return (
    <nav className="navbar navbar-static-top white-bg">
      <div className="navbar-header" style={{ minHeight: 60 }}>
        <div
          className="navbar-minimalize minimalize-styl-2 btn btn-primary "
          href="#"
        >
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <ul className="nav navbar-top-links navbar-right">
        <Dropdown as="li">
          <Dropdown.Toggle id="dropdown-custom-1" as={ToggleUser} />
          <UserMenu />
        </Dropdown>
      </ul>
    </nav>
  );
};

export default TopMenu;
