import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import StatisticsDriverMap from './StatisticsDriverMap';
import StatisticsDriverAreas from './StatisticsDriverAreas';

const DriverModal = ({ driver, campaign, onHide }) => {
  if (!driver) return null;
  return (
    <Modal size="xl" backdrop="static" show={!!driver} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Driver statistics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4">
            <h3>Statistics by areas</h3>
            <StatisticsDriverAreas areas={campaign.areas} driver={driver} />
          </div>
          <div className="col-md-8">
            <StatisticsDriverMap
              campaign={campaign}
              statistics={{
                drivers: [driver],
                areas: driver.areas,
                summary: {
                  total_impressions: driver.total_impressions,
                  total_distance: driver.total_distance,
                  rad: driver.rad,
                },
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DriverModal.propTypes = {
  driver: PropTypes.object,
  campaign: PropTypes.object,
  onHide: PropTypes.func,
};

export default DriverModal;
