import React from 'react';
import PropTypes from 'prop-types';
import FormattedNumber from '../../Intl/FormattedNumber';

const SwarmSummary = ({ statistics }) => (
  <div>
    <h3>Summary</h3>
    <div className="row">
      <div className="col-sm-6">
        <div className="ibox float-e-margins ibox-info">
          <div className="ibox-title">
            <b>Impressions Progress</b>
          </div>
          <div className="ibox-content">
            <h1 className="no-margins text-info">
              <FormattedNumber value={statistics.total_impressions} />
            </h1>
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="ibox float-e-margins ibox-info">
          <div className="ibox-title">
            <b>Distance (miles)</b>
          </div>
          <div className="ibox-content">
            <h1 className="no-margins text-success">
              <FormattedNumber value={statistics.total_distance} isMile />
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SwarmSummary.propTypes = {
  statistics: PropTypes.object,
};
export default SwarmSummary;
