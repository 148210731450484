import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import FormattedNumber from '../Intl/FormattedNumber';

const StatisticsSummary = ({ campaign, statistics }) => {
  const sold = Math.round(parseFloat(campaign.impressions_sold || 0));
  const total =
    sold > 0 ? parseFloat(statistics.inside.total_impressions).toFixed(0) : 0;
  const perSold = sold > 0 ? parseFloat((total / sold) * 100).toFixed(0) : 0;
  return (
    <div>
      <h3>Campaign Results</h3>
      <div className="row">
        <div className="col-sm-6">
          <div className="ibox float-e-margins ibox-info">
            <div className="ibox-title">
              <b>Impressions</b>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-info">
                <FormattedNumber value={statistics.inside.total_impressions} />
                <div style={{ fontSize: 12 }}> in interested areas</div>
              </h1>
              <div style={{ fontSize: 12, marginTop: 10 }}>
                Total <FormattedNumber value={statistics.total_impressions} />
              </div>
              {sold > 0 && (
                <>
                  <div className="stat-percent font-bold text-info">
                    <small>Delivered</small> {`${perSold}% `}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover id="popover-OverlayTrigger">
                          <Popover.Content>
                            Delivered {perSold}% of{' '}
                            <b>
                              <FormattedNumber value={sold} />
                            </b>{' '}
                            impressions expected
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <i
                          ref={ref}
                          {...triggerHandler}
                          className="fa fa-question-circle text-muted"
                        ></i>
                      )}
                    </OverlayTrigger>
                  </div>
                  <div className="progress progress-mini ">
                    <div
                      style={{ width: `${perSold}%` }}
                      className="progress-bar"
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="ibox float-e-margins ibox-info">
            <div className="ibox-title">
              <b>Distance (miles)</b>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-success">
                <FormattedNumber
                  value={statistics.inside.total_distance}
                  isMile
                />
                <div style={{ fontSize: 12 }}> in interested areas</div>
              </h1>
              <div style={{ fontSize: 12, marginTop: 10 }}>
                Total{' '}
                <FormattedNumber value={statistics.total_distance} isMile />
                miles
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4 d-none">
          <div className="ibox float-e-margins ibox-info">
            <div className="ibox-title">
              <b>Driving (hours)</b>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-warning">
                <FormattedNumber value={statistics.inside.total_time} isHour />
                <div style={{ fontSize: 12 }}> in interested areas</div>
              </h1>
              <div style={{ fontSize: 12, marginTop: 10 }}>
                Total <FormattedNumber value={statistics.total_time} isHour />
                hours
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 d-none">
          <div className="ibox float-e-margins ibox-info">
            <div className="ibox-title">
              <b>Time Parked (hours)</b>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins text-danger">
                <FormattedNumber
                  value={statistics.inside.total_time_parked}
                  isHour
                />
              </h1>
              <div>
                <FormattedNumber
                  value={statistics.outside.total_time_parked}
                  isHour
                >
                  {(value) => (
                    <div className="text-danger stat-percent font-bold text-success">
                      {value}
                    </div>
                  )}
                </FormattedNumber>
                <small>Outside</small>
              </div>
              <div>
                <FormattedNumber value={statistics.total_time_parked} isHour>
                  {(value) => (
                    <div className="text-danger stat-percent font-bold text-success">
                      {value}
                    </div>
                  )}
                </FormattedNumber>
                <small>Total</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StatisticsSummary.propTypes = {
  campaign: PropTypes.object,
  statistics: PropTypes.object,
};
export default StatisticsSummary;
