import React from 'react';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';
import MapContent from './MapContent';
import { computeDistanceBetween } from '../GMap/helpers';
import moment from '../moment';
const MapWrapper = ({ campaign, statistics, extraAreas }) => {
  const map = useGoogleMap();
  // get driver style
  const colors = {};
  // eslint-disable-next-line no-undef
  // const bounds = new google.maps.LatLngBounds();
  const routes = {}; // all routes
  const driverMarkers = {}; // market for route driver
  const tmpRoutes = {}; // temporary to generate routes
  // cache full tracks
  const tracks = statistics.drivers
    .reduce((acc, driver) => {
      // get driver style
      colors[driver.driver_id] = driver.style;
      // cache records
      driver.records.forEach((record) => {
        acc.push({
          ...record,
          driver_id: parseInt(driver.driver_id, 10),
          // eslint-disable-next-line no-undef
          position: new google.maps.LatLng(
            record.lat,
            record.lng || record.lon,
          ),
        });
      });
      return acc;
    }, [])
    .sort((a, b) => {
      if (a.r_time > b.r_time) return 1;
      if (a.r_time < b.r_time) return -1;
      return 0;
    });
  let numberOfDates = 1;
  if (tracks.length > 0) {
    numberOfDates =
      moment(tracks[tracks.length - 1].r_time).diff(
        moment(tracks[0].r_time),
        'days',
      ) + 1;
  }

  tracks.forEach((track) => {
    const driverId = track.driver_id;
    tmpRoutes[driverId] = tmpRoutes[driverId] || {
      routes: [],
      last_track: null,
    };
    driverMarkers[driverId] =
      driverMarkers[driverId] ||
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        map,
      });

    const { last_track: lastTrack } = tmpRoutes[driverId];
    // create new route
    if (
      !lastTrack ||
      computeDistanceBetween(lastTrack.position, track.position, numberOfDates)
    ) {
      const routeId = `${driverId}-${tmpRoutes[driverId].routes.length}`;
      tmpRoutes[track.driver_id].routes.push(routeId);
      routes[routeId] = routes[routeId] || {
        driverId,
        // eslint-disable-next-line no-undef
        overlay: new google.maps.Polyline({
          strokeColor: colors[driverId],
          strokeWeight: 2,
          strokeOpacity: 0.7,
          map,
        }),
      };
    }
    // eslint-disable-next-line no-param-reassign
    track.routeId =
      tmpRoutes[driverId].routes[tmpRoutes[driverId].routes.length - 1];

    tmpRoutes[driverId].last_track = track;

    // add bounds
    // bounds.extend(track.position);
  });

  return (
    <MapContent
      tracks={tracks}
      routes={routes}
      driverMarkers={driverMarkers}
      campaign={campaign}
      statistics={statistics}
      extraAreas={extraAreas}
    />
  );
};

MapWrapper.propTypes = {
  // center: PropTypes.any,
  extraAreas: PropTypes.any,
  campaign: PropTypes.object,
  statistics: PropTypes.object,
};
export default MapWrapper;
