import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber as IntlFormattedNumber } from 'react-intl';

export default function FormattedNumber(props) {
  const newProps = { ...props };
  if (newProps.isMile) {
    newProps.value *= 0.000621371;
    newProps.maximumFractionDigits =
      newProps.maximumFractionDigits !== undefined
        ? newProps.maximumFractionDigits
        : 2;
  } else if (newProps.isHour) {
    newProps.value /= 3600;
    newProps.maximumFractionDigits =
      newProps.maximumFractionDigits !== undefined
        ? newProps.maximumFractionDigits
        : 0;
  }
  return (
    <>
      {newProps.prefix && `${newProps.prefix} `}
      <IntlFormattedNumber
        maximumFractionDigits={newProps.maximumFractionDigits || 0}
        {...newProps}
      />
      {newProps.surfix && ` ${newProps.surfix}`}
    </>
  );
}

FormattedNumber.propTypes = {
  isMile: PropTypes.bool,
  isHour: PropTypes.bool,
  maximumFractionDigits: PropTypes.bool,
  value: PropTypes.any,
  surfix: PropTypes.any,
  prefix: PropTypes.any,
};
