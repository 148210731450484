import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '../../constains/variables';
import mapStyle from './mapStyle';

const libraries = ['geometry', 'drawing', 'places', 'visualization'];

const GMap = ({
  id = 'map',
  children,
  center = { lat: 52.509865, lng: -0.118092 },
  zoom = 10,
  mapContainerStyle = { height: 500, width: '100%' },
  onLoad,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });
  const onLoadG = React.useCallback((mapInstance) => {
    if (onLoad) onLoad(mapInstance);
  });

  const RenderMap = () => (
    <GoogleMap
      id={id}
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={zoom}
      options={{
        styles: mapStyle,
        controlSize: 24,
      }}
      onLoad={onLoadG}
    >
      {children}
    </GoogleMap>
  );

  return isLoaded ? RenderMap() : null;
};

GMap.propTypes = {
  id: PropTypes.string,
  center: PropTypes.object,
  zoom: PropTypes.number,
  mapContainerStyle: PropTypes.object,
  children: PropTypes.any,
  onLoad: PropTypes.func,
};
export default GMap;
