import React from 'react';
import PropTypes from 'prop-types';
import { Circle, Polygon, Rectangle } from '@react-google-maps/api';
import { MAP_CONFIG } from '../../constains/variables';

const ExtraAreas = ({ areas }) => {
  const { drawOptions } = MAP_CONFIG;
  return areas.map(({ type, criteria, distance }, index) => {
    switch (type) {
      case 'distance':
      case 'circle':
      case 'postcode':
        return (
          <Circle
            key={index.toString()}
            options={drawOptions}
            center={{ lat: criteria.lat, lng: criteria.lon }}
            radius={parseFloat(distance)}
          />
        );
      case 'rectangle':
      case 'bounding_box':
        // eslint-disable-next-line no-case-declarations
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(
          new window.google.maps.LatLng(
            criteria.top_left.lat,
            criteria.top_left.lon,
          ),
        );
        bounds.extend(
          new window.google.maps.LatLng(
            criteria.bottom_right.lat,
            criteria.bottom_right.lon,
          ),
        );
        return (
          <Rectangle
            key={index.toString()}
            options={drawOptions}
            bounds={bounds}
          />
        );
      default:
        // polygon
        return (
          <Polygon
            key={index.toString()}
            options={drawOptions}
            path={criteria.points.map((point) => ({
              lat: point[0],
              lng: point[1],
            }))}
          />
        );
    }
  });
};

ExtraAreas.propTypes = {
  areas: PropTypes.array,
};
export default ExtraAreas;
