export const getCurrentUserFromStorage = () =>
  JSON.parse(
    sessionStorage.getItem('currentUser') ||
      localStorage.getItem('currentUser') ||
      'null',
  );

export const getSignedInFromStorage = () =>
  (sessionStorage.getItem('signedIn') || localStorage.getItem('signedIn')) ===
  'true';

export const setCurrentUserToStorage = (user, remember = false) => {
  if (remember) {
    localStorage.setItem('signedIn', 'true');
    localStorage.setItem('currentUser', JSON.stringify(user));
  } else {
    sessionStorage.setItem('signedIn', 'true');
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }
};

export const resetDeviceStorage = () => {
  localStorage.removeItem('signedIn');
  localStorage.removeItem('currentUser', null);
  sessionStorage.removeItem('signedIn');
  sessionStorage.removeItem('currentUser', null);
};

export const isRemember = () => localStorage.getItem('signedIn') === 'true';

export const hasRoleSlug = (role) => {
  const currentUser = getCurrentUserFromStorage();
  if (currentUser && currentUser.group === role) return true;
  return false;
};
