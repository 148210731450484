/**
 *
 * Input
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const Field = ({
  type = 'text',
  name,
  label,
  prependIcon,
  prependText,
  form,
  classNameWrapper,
  ...rest
}) => {
  const error = getIn(form.errors, name);
  const touched = getIn(form.touched, name);
  const input = (
    <input
      {...rest}
      type={type}
      name={name}
      value={form.values[name]}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
      className={`form-control ${error && touched ? 'is-invalid' : ''}`}
    />
  );

  if (prependIcon || prependText) {
    return (
      <div className="form-group">
        {label && <label>{label}</label>}
        <div className="input-group ">
          <div className="input-group-prepend">
            <span className="input-group-text">
              {prependIcon && <span className={prependIcon}></span>}{' '}
              {prependText}
            </span>
          </div>
          {input}
          {error && touched && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        classNameWrapper !== undefined ? classNameWrapper : 'form-group'
      }
    >
      {label && <label>{label}</label>}
      {input}
      {error && touched && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

Field.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  classNameWrapper: PropTypes.string,
  form: PropTypes.object,
  prependIcon: PropTypes.string,
  prependText: PropTypes.string,
};

export default Field;
