import React, { useEffect } from 'react';
import { queryCampaigns } from '../apis/CampaignApi';
import { useLoading } from '../providers/LoadingProvider';
import CampaignItem from '../components/Campaign/CampaignItem';
import { hasRoleSlug } from '../utils/auth';
import CampaignItemMedia from '../components/Campaign/CampaignItemMedia';

function DashboardPage() {
  const { toggleLoading } = useLoading();
  const query = queryCampaigns();
  const { isLoading, isSuccess, error, data } = query;

  useEffect(() => {
    toggleLoading({ isLoading, error });
  }, [isLoading]);

  return (
    <>
      <div className="space-30"></div>
      <div className="row animated fadeInRight">
        {isSuccess &&
          data.map((campaign) => (
            <div className="col-lg-4 col-sm-6" key={campaign.id}>
              {!hasRoleSlug('media') && <CampaignItem campaign={campaign} />}
              {hasRoleSlug('media') && (
                <CampaignItemMedia campaign={campaign} />
              )}
            </div>
          ))}
      </div>
    </>
  );
}

export default DashboardPage;
