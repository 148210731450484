import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
// import { getThumbnail } from '../../../utils/helpers';

const WrapImages = ({ driver }) => {
  if (driver.driver.wrap && driver.driver.wrap.images)
    return (
      <div>
        <hr />
        <h3>Wrap photos</h3>
        <ImageGallery
          items={driver.driver.wrap.images.map((item) => ({
            original: item.path_string,
            thumbnail: item.path_string,
          }))}
        />
      </div>
    );
  return null;
};

WrapImages.propTypes = {
  driver: PropTypes.object,
};

export default WrapImages;
