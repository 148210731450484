import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { mutationSendEmailToResetPassword } from '../apis/AuthApi';
import Field from '../components/ExForm/Field';

function ForgotPasswordPage() {
  const mutation = mutationSendEmailToResetPassword();
  const { isLoading, isError, error, isSuccess, data } = mutation;
  return (
    <div className="animated fadeInRight">
      <h2 className="text-white">Forgot password</h2>
      <div className="ibox-content m-t text-left">
        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values) => mutation.mutate(values)}
        >
          {(form) => (
            <Form onSubmit={form.handleSubmit}>
              {isError && (
                <div className="alert alert-danger text-left">{error}</div>
              )}
              {isSuccess && data && (
                <div className="alert alert-success text-left">{data}</div>
              )}
              <p>
                Enter your email address and your password will be reset and
                emailed to you.{' '}
              </p>
              <Field
                type="email"
                name="email"
                placeholder="email"
                prependIcon="glyphicon glyphicon-envelope"
                form={form}
              />
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-primary block full-width m-b"
              >
                Reset password{' '}
                {isLoading && <i className="fa fa-spinner fa-spin"></i>}
              </button>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <Link to="/auth/login">
            <i className="fa fa-sign-in"></i> I remember my password
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
