import React from 'react';
import Collapse from '../../Collapse';

const Introduce = () => (
  <Collapse>
    <div className="row">
      <div className="col-xl-7 col-lg-6">
        <h2 className="m-b">What is an Drovo Digital Campaign?</h2>
        <p>
          Easily extend your on-car advertising onto mobile devices in
          real-time.
        </p>
        <ul>
          <li>Reach customers through true omni-channel advertising</li>
          <li>Boost your reach and sales</li>
          <li>
            Attribute online and in-store customers to transit media activity
          </li>
        </ul>
        <p>
          <b>Did you know:</b> Digital boosts OOH campaign performance (click
          through rate) by up to 64%!
        </p>
      </div>
      <div className="col-xl-5 col-lg-6 text-right">
        <img src="/digital.png" className="w-100" alt="" />
      </div>
    </div>
  </Collapse>
);

export default Introduce;
