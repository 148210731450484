import React, { useEffect, useState } from 'react';
import { HeatmapLayer } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const Heatmap = ({ isShow, control }) => {
  const [state, setState] = useState({
    prevAct: control.act,
    cacheData: [],
    data: [],
  });

  useEffect(() => {
    if (control.act === 'reset') {
      setState({
        cacheData: [],
        prevAct: 'reset',
        data: (control.tracks || []).map((track) => ({
          location: track.position,
          // weight: track.impr > 30 ? 30 : track.impr,
        })),
      });
    } else if (control.act === 'play') {
      if (state.prevAct === 'reset') {
        state.data = [];
        state.cacheData = [];
      }
      const track = control.tracks[control.currentTrackIndex];
      if (track) {
        state.cacheData.push(track.position);
      }
      if (state.cacheData.length >= 50 || !track) {
        state.data = [...state.data, ...state.cacheData];
        state.cacheData = [];
      }
      state.prevAct = 'play';
      setState({ ...state });
    }
  }, [control.act, control.currentTrackIndex, control.selectedDrivers]);

  if (isShow)
    return (
      <HeatmapLayer
        options={{
          radius: 8,
          maxIntensity: 16,
        }}
        data={state.data}
      />
    );
  return null;
};

Heatmap.propTypes = {
  isShow: PropTypes.bool,
  control: PropTypes.object,
  tracks: PropTypes.array,
};

export default Heatmap;
