import React from 'react';
import PropTypes from 'prop-types';
import { useCampaign } from '../../../../providers/CampaignProvider';
import Collapse from '../../../Collapse';
import StatisticsMap from '../../../StatisticsMap';
const DetailSwarmMap = ({ event }) => {
  const { campaign } = useCampaign();
  return (
    <Collapse>
      <StatisticsMap
        zoom={10}
        id="swarm-detail-map"
        campaign={campaign}
        statistics={event.statistics}
        extraAreas={[event.swarm_area]}
        center={{
          lat: event.swarm_area.criteria.lat,
          lng: event.swarm_area.criteria.lon,
        }}
      />
    </Collapse>
  );
};

DetailSwarmMap.propTypes = {
  event: PropTypes.object,
};

export default DetailSwarmMap;
