import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import moment from '../../moment';
import Collapse from '../../Collapse';
import { useCampaign } from '../../../providers/CampaignProvider';
import { SWARM_EVENT_STATUS } from '../../../constains/variables';
import SwarmModal from './SwarmModal';

const SwarmEvents = ({ swarmEvents }) => {
  const { campaign } = useCampaign();
  const [selectEvent, setSelectEvent] = useState();

  const columns = [
    {
      Header: '#',
      id: 'index',
      disableSortBy: true,
      accessor: (row, rowIndex) => rowIndex + 1,
    },
    {
      Header: 'Location',
      id: 'location_name',
      disableSortBy: true,
      accessor: (row) => row.location_name,
    },
    {
      Header: 'Date',
      id: 'start_date',
      accessor: (row) => `${row.swarm_date} ${row.start_time}`,
      Cell: (cell) => {
        const row = cell.row.original;
        const date = moment(row.swarm_date).format('Do MMM');
        const startTime = moment(row.start_time, 'HH:mm:ss');
        const times = `${startTime.format('hh:mma')}-${startTime
          .add(row.duration, 'm')
          .format('hh:mma')}`;
        return `${date} ${times}`;
      },
    },
    {
      Header: 'Status',
      id: 'status',
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`label label-${cell.row.original.status_stylish.style}`}
        >
          {cell.row.original.status_stylish.label}
        </span>
      ),
    },
    {
      Header: 'Number of vehicles',
      id: 'number_of_cars',
      disableSortBy: true,
      accessor: (row) => row.number_of_cars,
    },
    {
      Header: '',
      id: 'actions',
      disableSortBy: true,
      Cell: (cell) => {
        if (
          cell.row.original.status === SWARM_EVENT_STATUS.LIVE ||
          cell.row.original.status === SWARM_EVENT_STATUS.FINISHED
        )
          return (
            <div className="text-right">
              <Link
                className="btn btn-primary btn-xs m-0"
                to={`/campaign/${cell.row.original.campaign_id}/swarming-events/${cell.row.original.id}`}
              >
                Detail
              </Link>
            </div>
          );

        return (
          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary btn-xs m-0"
              onClick={() => setSelectEvent(cell.row.original)}
            >
              Detail
            </button>
          </div>
        );
      },
    },
  ];
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns: useMemo(() => columns, []),
        data: swarmEvents,
      },
      useSortBy,
    );

  return (
    <Collapse title="Your Domination Events">
      {(!swarmEvents || swarmEvents.length < 1) && (
        <>You have no previous events</>
      )}
      {swarmEvents && swarmEvents.length > 0 && (
        <div className="table-responsive">
          <Table striped hover {...getTableProps()} className="dataTable">
            <thead>
              <tr>
                {headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`text-nowrap ${
                      // eslint-disable-next-line no-nested-ternary
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'sorting_desc'
                          : 'sorting_asc'
                        : column.disableSortBy || 'sorting'
                    }`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <SwarmModal
        campaign={campaign}
        event={selectEvent}
        onClose={() => setSelectEvent(null)}
      />
    </Collapse>
  );
};

SwarmEvents.propTypes = {
  swarmEvents: PropTypes.array,
};
export default SwarmEvents;
