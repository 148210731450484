import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import FormattedNumber from '../Intl/FormattedNumber';
import Collapse from '../Collapse';
import DriverModal from './DriverDetail/DriverModal';

const columns = [
  {
    Header: 'Status',
    id: 'status',
    accessor: (row) => row.driver.status_stylish.label,
    Cell: (cell) => (
      <span
        className={`label label-${cell.row.original.driver.status_stylish.style}`}
      >
        {cell.row.original.driver.status_stylish.label}
      </span>
    ),
  },
  {
    Header: 'Vehicle',
    id: 'vehicle',
    accessor: (row) => {
      const { vehicle } = row.driver.user;
      if (vehicle) {
        const { make, model } = vehicle;
        return `${make && make.name ? make.name : ''} ${
          model && model.model ? model.model : ''
        }`;
      }
      return '';
    },
  },
  {
    Header: 'Wrap types',
    id: 'wrap_types',
    disableSortBy: true,
    accessor: (row) =>
      row.driver.wrap_types.map((wrapType) => (
        <span
          className="badge badge-secondary"
          style={{ marginRight: 2 }}
          key={wrapType}
        >
          {wrapType}
        </span>
      )),
  },
  {
    Header: 'Driver No.',
    id: 'driver',
    accessor: (row) => row.driver.driver_id,
  },
  {
    Header: 'Nickname',
    id: 'nickname',
    accessor: (row) => `${row.driver.user.nickname || ''}`,
  },
  {
    Header: 'Impressions',
    id: 'inside.total_impressions',
    accessor: 'inside.total_impressions',
    Cell: (cell) => (
      <div className="text-info">
        <b>
          <FormattedNumber value={cell.value} />
        </b>
        {' / '}
        <FormattedNumber value={cell.row.original.total_impressions} />
      </div>
    ),
  },
  {
    Header: 'Distance (miles)',
    id: 'inside.total_distance',
    accessor: 'inside.total_distance',
    Cell: (cell) => (
      <div className="text-success">
        <b>
          <FormattedNumber value={cell.value} isMile />
        </b>
        {' / '}
        <FormattedNumber value={cell.row.original.total_distance} isMile />
      </div>
    ),
  },
  /*
  {
    Header: 'Driving (hours)',
    id: 'inside.total_time',
    accessor: 'inside.total_time',
    Cell: (cell) => (
      <div className="text-warning">
        <b>
          <FormattedNumber value={cell.value} isHour />
        </b>
        {' / '}
        <FormattedNumber value={cell.row.original.total_time} isHour />
      </div>
    ),
  },
   */
];

const StatisticsDrivers = ({ campaign, statistics }) => {
  const { drivers } = statistics;
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns: useMemo(() => columns, []),
        data: drivers,
      },
      useSortBy,
    );
  const [selectDriver, setSelectDriver] = useState(null);

  return (
    <Collapse title="Driver Results">
      <div className="table-responsive">
        <Table striped {...getTableProps()} className="dataTable">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`text-nowrap ${
                    // eslint-disable-next-line no-nested-ternary
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorting_desc'
                        : 'sorting_asc'
                      : column.disableSortBy || 'sorting'
                  }`}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                  <td>
                    <button
                      type="button"
                      className="btn btn-default btn-xs m-0"
                      onClick={() => setSelectDriver(row.original)}
                    >
                      Detail
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <DriverModal
        campaign={campaign}
        driver={selectDriver}
        onHide={() => setSelectDriver(null)}
      />
    </Collapse>
  );
};

StatisticsDrivers.propTypes = {
  statistics: PropTypes.object,
  campaign: PropTypes.object,
};
export default StatisticsDrivers;
