import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '../../Collapse';

const Start = ({ onStart }) => (
  <Collapse className="animated fadeIn">
    <p>Currently, you are not reducing your campaign’s carbon footprint.</p>
    <h2>Want to change this?</h2>
    <button type="button" onClick={onStart} className="btn btn-primary btn-sm">
      Offset Carbon Now
    </button>
  </Collapse>
);

Start.propTypes = {
  onStart: PropTypes.func,
};

export default Start;
