import React from 'react';
import PropTypes from 'prop-types';
import MapWrapper from './MapWrapper';
import Gmap from '../GMap';
import { getCenterOfAreas } from '../GMap/helpers';

const StatisticsMap = ({ id = 'map', onLoad, zoom = 10, center, ...props }) => (
  <Gmap
    id={id}
    center={center}
    onLoad={(map) => {
      if (!center) {
        map.setCenter(getCenterOfAreas(props.campaign.areas));
      }
      if (onLoad) onLoad(map);
    }}
    zoom={zoom}
  >
    <MapWrapper {...props} />
  </Gmap>
);

StatisticsMap.propTypes = {
  id: PropTypes.string,
  center: PropTypes.object,
  zoom: PropTypes.number,
  campaign: PropTypes.object,
  onLoad: PropTypes.func,
};
export default StatisticsMap;
