/**
 *
 * Input
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ label, name, id }) => (
  <div className="custom-control custom-checkbox form-group">
    <input
      type="checkbox"
      className="custom-control-input"
      name={name}
      id={id || name}
    />
    <label className="custom-control-label" htmlFor={id || name}>
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default Checkbox;
