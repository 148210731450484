import React from 'react';
import PropTypes from 'prop-types';
import StatisticsMap from '../../StatisticsMap';

const StatisticsDriverMap = ({ campaign, statistics }) => (
  <StatisticsMap id="driverMap" campaign={campaign} statistics={statistics} />
);

StatisticsDriverMap.propTypes = {
  campaign: PropTypes.object,
  statistics: PropTypes.object,
};
export default StatisticsDriverMap;
