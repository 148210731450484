import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useExpanded, useSortBy, useTable } from 'react-table';
import FormattedNumber from '../Intl/FormattedNumber';
import Collapse from '../Collapse';

const columns = [
  {
    id: 'expander',
    Header: 'Area',
    Cell: (cell) =>
      cell.row.canExpand ? (
        <span {...cell.row.getToggleRowExpandedProps()}>
          {cell.row.original.name}{' '}
          {cell.row.isExpanded ? (
            <i className="fa fa-angle-up"></i>
          ) : (
            <i className="fa fa-angle-down"></i>
          )}
        </span>
      ) : (
        <span style={{ paddingLeft: 10 }}>{cell.row.original.name}</span>
      ),
    accessor: 'area',
  },
  {
    Header: 'Impressions',
    accessor: 'total_impressions',
    Cell: (cell) => (
      <b className="text-info">
        <FormattedNumber value={cell.value} />
      </b>
    ),
  },
  {
    Header: 'Distance (miles)',
    accessor: 'total_distance',
    Cell: (cell) => (
      <b className="text-success">
        <FormattedNumber value={cell.value} isMile />
      </b>
    ),
  },
  /*
  {
    Header: 'Driving (hours)',
    accessor: 'total_time',
    Cell: (cell) => (
      <b className="text-warning">
        <FormattedNumber value={cell.value} isHour />
      </b>
    ),
  },
   */
];

const StatisticsAreas = ({ statistics }) => {
  const { areas, summary } = statistics;
  const primaryAreas = areas.filter((area) => area.is_primary);
  const secondaryAreas = areas.filter((area) => !area.is_primary);

  const data = React.useMemo(
    () => [
      {
        name: 'Primary Areas',
        ...primaryAreas.reduce(
          (acc, area) => {
            acc.total_impressions += area.total_impressions;
            acc.total_distance += area.total_distance;
            acc.total_time += area.total_time;
            return acc;
          },
          {
            total_impressions: 0,
            total_distance: 0,
            total_time: 0,
          },
        ),
        subRows: primaryAreas.filter((area) => area.slug !== 'uk'),
      },
      {
        name: 'Secondary Areas',
        ...secondaryAreas.reduce(
          (acc, area) => {
            acc.total_impressions += area.total_impressions;
            acc.total_distance += area.total_distance;
            acc.total_time += area.total_time;
            return acc;
          },
          {
            total_impressions: 0,
            total_distance: 0,
            total_time: 0,
          },
        ),
        subRows: secondaryAreas,
      },
    ],
    [],
  );
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns: useMemo(() => columns, []),
        data,
      },
      useSortBy,
      useExpanded,
    );

  return (
    <Collapse title="Reach">
      <div className="table-responsive">
        <Table striped {...getTableProps()} className="dataTable">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorting_desc'
                        : 'sorting_asc'
                      : 'sorting'
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
            <tr>
              <td>Outside of interested areas</td>
              <td className="text-info">
                <FormattedNumber value={summary.outside.total_impressions} />
              </td>
              <td className="text-success">
                <FormattedNumber
                  value={summary.outside.total_distance}
                  isMile
                />
              </td>
              <td className="text-warning d-none">
                <FormattedNumber value={summary.outside.total_time} isHour />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th className="text-info">
                <FormattedNumber value={summary.total_impressions} />
              </th>
              <th className="text-success">
                <FormattedNumber value={summary.total_distance} isMile />
              </th>
              <th className="text-warning d-none">
                <FormattedNumber value={summary.total_time} isHour />
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </Collapse>
  );
};

StatisticsAreas.propTypes = {
  statistics: PropTypes.object,
};
export default StatisticsAreas;
