export const useDrawMap = () => {
  let drawAreas = [];

  const addClickEvent = (area) => {
    window.google.maps.event.addListener(area.overlay, 'click', () => {
      // eslint-disable-next-line no-param-reassign
      area.overlay.isSelected = !area.overlay.isSelected;
      if (area.overlay.isSelected) {
        area.overlay.setOptions({
          strokeColor: '#ff2242',
        });
      } else {
        area.overlay.setOptions({
          strokeColor: '#5a6aff',
        });
      }
    });
    return area;
  };

  const resetSelectAreas = () => {
    for (let i = 0; i < drawAreas.length; i += 1) {
      drawAreas[i].overlay.isSelected = false;
      drawAreas[i].overlay.setOptions({
        strokeColor: '#5a6aff',
      });
    }
  };

  const addDrawArea = (area) => {
    resetSelectAreas();
    addClickEvent(area);
    // eslint-disable-next-line no-param-reassign
    area.overlay.isSelected = true;
    area.overlay.setOptions({
      strokeColor: 'red',
    });
    drawAreas.push({
      ...area,
    });
    return drawAreas;
  };

  const removeDrawAreas = (all = false) => {
    drawAreas
      .filter((area) => all || area.overlay.isSelected)
      .forEach((area) => {
        area.overlay.setMap(null);
      });
    drawAreas = drawAreas.filter((area) => !area.overlay.isSelected);
    return drawAreas;
  };

  return { addDrawArea, removeDrawAreas };
};
