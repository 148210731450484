import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntercom } from 'react-use-intercom';
import moment from '../../moment';
import Collapse from '../../Collapse';
import { updateObjectViews } from '../../../utils/helpers';

const Confirmed = ({ event }) => {
  const { showNewMessages } = useIntercom();
  const startTime = moment(event.start_time, 'HH:mm:ss');

  useEffect(() => () => {
    updateObjectViews(event.id, 'swarm_event');
  });

  return (
    <Collapse className="animated fadeIn ">
      <h3 className="m-b">
        <b>Swarm Accepted:</b> {moment(event.swarm_date).format('Do MMMM YYYY')}
        {', '}
        {startTime.format('hh:mmA')}
        {' - '}
        {startTime.add(event.duration, 'm').format('hh:mmA')}
        {', '}
        {event.location_name}
      </h3>
      You’re all set and ready to swarm!
      <div className="progress">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-success "
          role="progressbar"
          style={{ width: '33%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-warning "
          role="progressbar"
          style={{ width: '33%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-info "
          role="progressbar"
          style={{ width: '34%' }}
        />
      </div>
      <p className="m-t-md">
        Your Domination Event is confirmed and you can access it in your list of
        Domination Events below.
      </p>
      <button
        type="button"
        onClick={() =>
          showNewMessages(
            'Hello, I would like to know about my carbon offset request process.',
          )
        }
        className="btn btn-primary btn-sm m-r"
      >
        Contact Us
      </button>
    </Collapse>
  );
};

Confirmed.propTypes = {
  event: PropTypes.object,
};

export default Confirmed;
