import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { OFFICIAL_PAGE, SWARM_EVENT_STATUS } from '../../constains/variables';
import { objectReadyViewed, swarmStylish } from '../../utils/helpers';

// eslint-disable-next-line react/prop-types
const Li = ({ path, children, ...props }) => {
  const match = useRouteMatch({
    path,
  });

  return (
    <li className={match && match.isExact ? 'active' : ''} {...props}>
      {children}
    </li>
  );
};

const Sidebar = ({ campaign, digital, carbonOffset, swarmEvents }) => {
  let swarmStatus = swarmStylish();
  if (
    swarmEvents.find((swarm) => swarm.status === SWARM_EVENT_STATUS.PENDING)
  ) {
    swarmStatus = swarmStylish(SWARM_EVENT_STATUS.PENDING);
  } else if (
    swarmEvents.find(
      (swarm) =>
        swarm.status === SWARM_EVENT_STATUS.CONFIRMED &&
        !objectReadyViewed(swarm.id, 'swarm_event'),
    )
  ) {
    swarmStatus = swarmStylish(SWARM_EVENT_STATUS.CONFIRMED);
  } else if (
    swarmEvents.find((swarm) => swarm.status === SWARM_EVENT_STATUS.LIVE)
  ) {
    swarmStatus = swarmStylish(SWARM_EVENT_STATUS.LIVE);
  }

  const clientLogo =
    campaign && campaign.brands && campaign.brands.length > 0
      ? campaign.brands[0].logo
      : '';
  const logo = {
    org: '/logo.light.svg',
    min: '/drovo.png',
  };

  return (
    <div className="navbar-default navbar-static-side">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header text-center">
            <img src={logo.org} className="img-fluid nav-label" alt="" />
            <img className="logo-element" src={logo.min} alt="" />
            {clientLogo && (
              <img
                src={clientLogo}
                className="img-fluid nav-label mt-3"
                alt=""
              />
            )}
          </li>
          <Li title="Your on screen digital campaigns">
            <a href={`${OFFICIAL_PAGE}self-serve-ads/my-campaigns`}>
              <i className="fa fa-tv"></i>
              <span className="nav-label"> Digital Campaigns</span>
            </a>
          </Li>
          <Li path="/" title="Switch Campaign">
            <Link to="/">
              <i className="fa fa-th-large"></i>
              <span className="nav-label">
                {' '}
                {campaign ? 'Switch Campaign' : 'Your Campaigns'}
              </span>
            </Link>
          </Li>
          {campaign && digital && carbonOffset && (
            <>
              <Li path="/campaign/:campaignId" title="Main Campaign">
                <Link to={`/campaign/${campaign.id}`}>
                  <i className="fa fa-car"></i>
                  <span className="nav-label"> Main Campaign</span>
                  <span
                    className={`label label-${campaign.status_stylish.style} float-right`}
                  >
                    {campaign.status_stylish.label}
                  </span>
                </Link>
              </Li>
              <Li
                path="/campaign/:campaignId/digital-add-on"
                title="Digital Add-on"
              >
                <Link to={`/campaign/${campaign.id}/digital-add-on`}>
                  <i className="fa fa-wifi"></i>
                  <span className="nav-label"> Digital Add-on</span>
                  <span
                    className={`label label-${digital.status_stylish.style} float-right`}
                  >
                    {digital.status_stylish.label}
                  </span>
                </Link>
              </Li>
              <Li
                path="/campaign/:campaignId/swarming-events/:eventId?"
                title="Domination Events"
              >
                <Link to={`/campaign/${campaign.id}/swarming-events`}>
                  <i className="fa fa-location-arrow"></i>
                  <span className="nav-label"> Domination Events</span>
                  <span
                    className={`label label-${swarmStatus.style} float-right`}
                  >
                    {swarmStatus.label}
                  </span>
                </Link>
              </Li>
              <Li
                path="/campaign/:campaignId/carbon-offset"
                title="Carbon Offset"
              >
                <Link to={`/campaign/${campaign.id}/carbon-offset`}>
                  <i className="fa fa-tree"></i>
                  <span className="nav-label"> Carbon Offset</span>
                  <span
                    className={`label label-${carbonOffset.status_stylish.style} float-right`}
                  >
                    {carbonOffset.status_stylish.label}
                  </span>
                </Link>
              </Li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
Sidebar.propTypes = {
  carbonOffset: PropTypes.object,
  digital: PropTypes.object,
  swarmEvents: PropTypes.array,
  campaign: PropTypes.object,
};
export default Sidebar;
