export const API_ROOT = 'https://admin-console.drovo.media/api/';
// export const API_ROOT = 'http://local3.adverttu.com/api/';
export const OFFICIAL_PAGE = 'https://drovo.media/';
// export const OFFICIAL_PAGE = 'http://localhost:3000/';
export const GOOGLE_API_KEY = 'AIzaSyC2DWLRZKtLIR4WZxAz5xIO-zIt_N5mqI8';
// export const GOOGLE_API_KEY = 'AIzaSyA8Ln9cPvKwmbmocnucijjT0gQH41hiQLQ';
export const INTERCOM_APP_ID = 'p4ejlhnf';
export const YANDEX_ACCOUNT_ID = 82933213;

export const MAP_INIT_MAX_DISTANCE_POINTS = 4000; // lowest max distance
export const MAP_STEP_MAX_DISTANCE_POINTS = 1; // the max distance increase by multiple 300 with number days of selected dates
export const QUERY_CACHE_TIME = 15 * 60 * 1000; // 15 mins

export const AUTH_LAYOUT = '/layouts/AUTH_LAYOUT';
export const PROTECT_LAYOUT = '/layouts/PROTECT_LAYOUT';

export const MENU_USER = '/menus/MENU_USER';
export const MENU_GROUP_CAMPAIGN = '/menus/MENU_GROUP_CAMPAIGN';

export const DIGITAL_STATUS = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  DENIED: 'denied',
  LIVE: 'live',
};

export const DIGITAL_REPORT_TYPE = {
  REPORT_1X1: 'report_1x1',
  REPORT_NORMAL: 'report_normal',
};

export const CARBON_OFFSET_STATUS = {
  PENDING: 'pending',
  // CONFIRMED: 'confirmed',
  // DENIED: 'denied',
  LIVE: 'live',
};

export const SWARM_EVENT_STATUS = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  DENIED: 'denied',
  LIVE: 'live',
  PLANNING: 'planning',
  FINISHED: 'finished',
};

export const SWARM_EVENT_DRIVER_STATUS = {
  INVITE: 'invite',
  INTERESTED: 'interested',
  UNINTERESTED: 'uninterested',
};

export const CAMPAIGN_STATUS = {
  DRAFT: 'draft',
  APPLY: 'apply',
  MATCH: 'match', // ready to match with drivers
  RUN: 'run', // running
  FINISH: 'finish', // done
  PAUSE: 'pause', // pause campaign
};

export const CAMPAIGN_DRIVER_STATUS = {
  APPLY: 'apply', // driver apply to campaign
  POSTPONE: 'postpone', // driver postpone the campaign
  REJECT: 'reject', // driver reject the invitation
  INVITE: 'invite', // advertiser invite driver to join in the campaign
  UNINVITE: 'uninvite', // advertiser un-invited driver to join in the campaign
  DECLINE: 'decline', // advertiser decline the apply
  MATCH: 'match',
  RUN: 'run',
  FINISH: 'finish',
  CAMPAIGN_FINISH: 'campaign_finish',
};

export const MAP_CONFIG = {
  drawOptions: {
    strokeColor: '#5a6aff',
    strokeOpacity: 0.6,
    fillOpacity: 0.25,
    strokeWeight: 2,
    zIndex: 1,
  },
};
