import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import FormattedNumber from '../../Intl/FormattedNumber';

const columns = [
  {
    Header: 'Area',
    accessor: 'area_name',
  },
  {
    Header: 'Impressions',
    accessor: 'total_impressions',
    Cell: (cell) => (
      <span className="text-info">
        <FormattedNumber value={cell.value} />
      </span>
    ),
  },
  {
    Header: 'Distance (miles)',
    accessor: 'total_distance',
    Cell: (cell) => (
      <span className="text-success">
        <FormattedNumber value={cell.value} isMile />
      </span>
    ),
  },
];

const StatisticsDriverAreas = ({ areas, driver }) => {
  const interestedAreasIds = areas.map((area) => area.id);
  const data = (driver.areas || [])
    .filter((area) => interestedAreasIds.includes(parseInt(area.area_id, 10)))
    .map((area) => ({
      ...area,
      // eslint-disable-next-line eqeqeq
      area_name: areas.find((item) => item.id == area.area_id).name,
    }));
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="table-responsive">
      <Table striped {...getTableProps()} className="dataTable">
        <thead>
          <tr>
            {headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Inside</td>
            <th className="text-info">
              <FormattedNumber value={driver.inside.total_impressions} />
            </th>
            <th className="text-success">
              <FormattedNumber value={driver.inside.total_distance} isMile />
            </th>
            <th className="text-warning d-none">
              <FormattedNumber value={driver.inside.total_time} isHour />
            </th>
          </tr>
          <tr>
            <td>Outside</td>
            <th className="text-info">
              <FormattedNumber value={driver.outside.total_impressions} />
            </th>
            <th className="text-success">
              <FormattedNumber value={driver.outside.total_distance} isMile />
            </th>
            <th className="text-warning d-none">
              <FormattedNumber value={driver.outside.total_time} isHour />
            </th>
          </tr>
          <tr>
            <th>Total</th>
            <th className="text-info">
              <FormattedNumber value={driver.total_impressions} />
            </th>
            <th className="text-success">
              <FormattedNumber value={driver.total_distance} isMile />
            </th>
            <th className="text-warning d-none">
              <FormattedNumber value={driver.total_time} isHour />
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

StatisticsDriverAreas.propTypes = {
  areas: PropTypes.array,
  driver: PropTypes.object,
};
export default StatisticsDriverAreas;
