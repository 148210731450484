import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Link } from 'react-router-dom';
const Collapse = ({ title, children, className = '' }) => {
  const ref = React.createRef('collapse');

  const toggleCollapse = (event) => {
    event.preventDefault();
    const $obj = $(event.currentTarget);
    const ibox = $obj.closest('div.ibox');
    const button = $obj.find('i');
    const content = ibox.children('.ibox-content');
    content.slideToggle(200);
    button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
    ibox.toggleClass('').toggleClass('border-bottom');
    setTimeout(() => {
      ibox.resize();
      ibox.find('[id^=map-]').resize();
    }, 50);
  };

  return (
    <div className={`ibox ${className}`}>
      {title && (
        <div className="ibox-title">
          <h5>{title}</h5>
          <div className="ibox-tools">
            <Link
              to="/"
              onClick={toggleCollapse}
              className="collapse-link"
              ref={ref}
            >
              <i className="fa fa-chevron-up"></i>
            </Link>
          </div>
        </div>
      )}
      <div className="ibox-content">{children}</div>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};
export default Collapse;
