import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';
import { useAuth } from '../../providers/AuthProvider';
import { MENU_USER, OFFICIAL_PAGE } from '../../constains/variables';

const UserMenu = () => {
  const { signOut } = useAuth();
  const menus = routes.reduce((acc, route, idx) => {
    const { path, attributes = {}, name } = route;
    if (route.menu === MENU_USER) {
      acc.push(
        <li key={path}>
          <Link to={path} className="dropdown-item text-nowrap">
            {attributes.icon && <i className={`${attributes.icon} mr-2`}></i>}
            {name}
          </Link>
        </li>,
      );
      acc.push(
        <li
          key={`${path}_${idx.toString()}`}
          className="dropdown-divider"
        ></li>,
      );
    }
    return acc;
  }, []);
  menus.push(
    <li key="official_site">
      <div className="text-center link-block">
        <Link
          to="/"
          className="dropdown-item text-nowrap"
          onClick={(event) => {
            event.preventDefault();
            window.location = `${OFFICIAL_PAGE}self-serve-ads/redirect`;
          }}
        >
          <strong>Home</strong>
          <i className="fa fa-home ml-1"></i>
        </Link>
      </div>
    </li>,
  );
  menus.push(
    <li key="logout_menu">
      <div className="text-center link-block">
        <Link
          to="/"
          className="dropdown-item text-nowrap"
          onClick={(event) => {
            event.preventDefault();
            signOut();
          }}
        >
          <strong>Logout</strong>
          <i className="fa fa-sign-out ml-1"></i>
        </Link>
      </div>
    </li>,
  );
  return (
    <Dropdown.Menu as="ul" className="dropdown-alerts dropdown-2">
      {menus}
    </Dropdown.Menu>
  );
};

export default UserMenu;
