import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { getThumbnail } from '../../utils/helpers';
const Gallery = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    $('.gallery_item').height(($('.gallery_item').width() * 3) / 4);
  }, []);

  return (
    <>
      <div className="row no-gutters">
        {images.map((image, index) => (
          <div
            key={index.toString()}
            className="col-12 col-xs-6 col-sm-4 col-md-3 col-lg-2 p-1"
          >
            <BackgroundImage
              onClick={() => openLightbox(index)}
              wrapperClassName="gallery_item"
              height="100%"
              lazyLoad
              src={getThumbnail(image.path_string)}
            />
          </div>
        ))}
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              showArrows
              currentIndex={currentImage}
              views={images.map((image) => ({
                source: {
                  regular: image.path_string,
                  thumbnail: getThumbnail(image.path_string, 'small'),
                  fullscreen: image.path_string,
                },
                caption: image.title || '',
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
};

export default Gallery;
