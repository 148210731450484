import React from 'react';
import PropTypes from 'prop-types';
import CampaignInfo from './CampaignInfo';
import { hasRoleSlug } from '../../utils/auth';
import { useCampaign } from '../../providers/CampaignProvider';
function CampaignLayout({ children }) {
  const { campaign } = useCampaign(); // load local campaign
  if (campaign) {
    const isMedia = hasRoleSlug('media');

    return (
      <div className={`animated fadeInRight ${isMedia ? 'container' : ''}`}>
        <h2>
          {`${campaign.name} `}
          <span className={`badge badge-${campaign.status_stylish.style}`}>
            <i className="fa fa-circle small"></i>{' '}
            {campaign.status_stylish.label}
          </span>
        </h2>
        <div className="row">
          <div
            className={`animated fadeInUp ${isMedia ? 'col-12' : 'col-md-9'}`}
          >
            {children}
          </div>
          {!isMedia && (
            <div className="col-md-3">
              <CampaignInfo campaign={campaign} />
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
}

CampaignLayout.propTypes = {
  children: PropTypes.any,
};
export default CampaignLayout;
