import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Gallery from '../Gallery';

const VehicleGallery = ({ show, drivers, onHide }) => {
  if (show)
    return (
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h5>Vehicle Gallery</h5>
          <div className="ibox-tools">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                onHide();
              }}
              className="collapse-link"
            >
              Close
            </Link>
          </div>
        </div>
        <div className="ibox-content">
          <Gallery
            images={drivers.reduce((images, driver) => {
              if (
                driver.driver &&
                driver.driver.wrap &&
                driver.driver.wrap.images
              ) {
                driver.driver.wrap.images.forEach((image) => {
                  images.push({
                    path_string: image.path_string,
                    title: `${driver.driver.user.first_name} ${driver.driver.user.last_name}`,
                  });
                });
              }
              return images;
            }, [])}
          />
        </div>
      </div>
    );
  return null;
};

VehicleGallery.propTypes = {
  show: PropTypes.bool,
  drivers: PropTypes.array,
  onHide: PropTypes.func,
};

export default VehicleGallery;
