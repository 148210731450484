import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CampaignLayout from '../components/Campaign/CampaignLayout';
import Start from '../components/Campaign/DigitalAddOn/Start';
import Introduce from '../components/Campaign/DigitalAddOn/Introduce';
import Request from '../components/Campaign/DigitalAddOn/Request';
import { mutationCampaignDigital } from '../apis/CampaignApi';
import { useLoading } from '../providers/LoadingProvider';
import { useCampaign } from '../providers/CampaignProvider';
import { DIGITAL_STATUS } from '../constains/variables';
import Pending from '../components/Campaign/DigitalAddOn/Pending';
import Confirmed from '../components/Campaign/DigitalAddOn/Confirmed';
import Live from '../components/Campaign/DigitalAddOn/Live';
import RequestReport from '../components/Campaign/DigitalAddOn/RequestReport';

function CampaignDigitalAddOnPage() {
  const { campaignId } = useParams();
  const { campaign, digital } = useCampaign();
  const { status } = digital || {};
  const [step, setStep] = useState(1);
  const mutation = mutationCampaignDigital();

  const { toggleLoading } = useLoading(); // loading
  useEffect(() => {
    toggleLoading({ ...mutation });
  }, [mutation.isLoading]);

  let component = null;
  switch (status) {
    case DIGITAL_STATUS.PENDING:
      component = <Pending />;
      break;
    case DIGITAL_STATUS.CONFIRMED:
      component = <Confirmed campaign={campaign} digital={digital} />;
      break;
    case DIGITAL_STATUS.LIVE:
      component = <Live campaign={campaign} digital={digital} />;
      break;
    default:
      component = (
        <>
          {step === 1 && <Start onStart={() => setStep(2)} />}
          {step === 2 && (
            <Request
              onCancel={() => setStep(1)}
              onRequest={() => mutation.mutate(campaignId)}
            />
          )}
        </>
      );
  }

  return (
    <CampaignLayout>
      {component}
      <RequestReport />
      <Introduce />
    </CampaignLayout>
  );
}

export default CampaignDigitalAddOnPage;
