import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '../../Collapse';

const Request = ({ onCancel, onRequest }) => (
  <Collapse className="animated fadeIn ">
    <h2 className="m-b">Submit your request</h2>
    Requesting a campaign
    <div className="progress">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        className="progress-bar progress-bar-striped bg-success "
        role="progressbar"
        style={{ width: '25%' }}
      ></div>
    </div>
    <h3 className="pt-3">Your Digital campaign is one click away</h3>
    <p>
      Our team will contact you soon to agree your messaging, creatives and
      details.
    </p>
    <button
      type="button"
      onClick={onCancel}
      className="btn btn-outline btn-primary btn-sm m-r"
    >
      Cancel
    </button>
    <button
      type="button"
      onClick={onRequest}
      className="btn btn-primary btn-sm"
    >
      Request Digital Campaign
    </button>
  </Collapse>
);

Request.propTypes = {
  onCancel: PropTypes.func,
  onRequest: PropTypes.func,
};

export default Request;
