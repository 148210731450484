import React from 'react';
import { QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { YMInitializer } from 'react-yandex-metrika';
import ProtectLayout from './layouts/ProtectLayout';

import AuthProvider, { useAuth } from './providers/AuthProvider';
import queryClient from './apis/query_client';
import LoadingProvider from './providers/LoadingProvider';
import {
  INTERCOM_APP_ID,
  OFFICIAL_PAGE,
  YANDEX_ACCOUNT_ID,
} from './constains/variables';

import MapPage from './pages/MapPage';
import AccessPage from './pages/AccessPage';
// eslint-disable-next-line no-unused-vars
// import AuthLayout from './layouts/AuthLayout';

const ProtectedRoute = () => {
  const { signedIn } = useAuth();
  if (!signedIn) {
    window.location = `${OFFICIAL_PAGE}login`;
    return null;
  }
  return <ProtectLayout />;
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <IntlProvider locale="en">
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <AuthProvider>
          <LoadingProvider>
            <YMInitializer
              accounts={[YANDEX_ACCOUNT_ID]}
              options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
              }}
            />
            <Router>
              <Switch>
                <Route path="/map" component={MapPage} />
                <Route path="/_access_/:uuid" component={AccessPage} />
                <ProtectedRoute />
              </Switch>
            </Router>
          </LoadingProvider>
        </AuthProvider>
      </IntercomProvider>
    </IntlProvider>
  </QueryClientProvider>
);

export default App;
