import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useExpanded, useTable } from 'react-table';
import FormattedNumber from '../../Intl/FormattedNumber';

const columns = [
  {
    id: 'expander',
    Header: 'Area',
    Cell: (cell) =>
      cell.row.canExpand ? (
        <span {...cell.row.getToggleRowExpandedProps()}>
          {cell.row.original.name}{' '}
          {cell.row.isExpanded ? (
            <i className="fa fa-angle-up"></i>
          ) : (
            <i className="fa fa-angle-down"></i>
          )}
        </span>
      ) : (
        <span style={{ paddingLeft: 10 }}>{cell.row.original.name}</span>
      ),
    accessor: 'area',
  },
  {
    Header: 'Impressions',
    accessor: 'total_impressions',
    Cell: (cell) => (
      <span className="text-info">
        <FormattedNumber value={cell.value} />
      </span>
    ),
  },
  {
    Header: 'Distance (miles)',
    accessor: 'total_distance',
    Cell: (cell) => (
      <span className="text-success">
        <FormattedNumber value={cell.value} isMile />
      </span>
    ),
  },
  /*
  {
    Header: 'Driving (hours)',
    accessor: 'total_time',
    Cell: (cell) => (
      <span className="text-warning">
        <FormattedNumber value={cell.value} isHour />
      </span>
    ),
  },
   */
];

const StatisticsDriverAreas = ({ areas, driver }) => {
  const interestedAreasIds = areas.map((area) => area.id);
  const rawData = (driver.areas || [])
    .filter((area) => interestedAreasIds.includes(parseInt(area.area_id, 10)))
    .map((area) => ({
      ...area,
      // eslint-disable-next-line eqeqeq
      ...areas.find((item) => item.id == area.area_id),
    }));
  const primaryAreas = rawData.filter((area) => area.is_primary);
  const secondaryAreas = rawData.filter((area) => !area.is_primary);
  const data = React.useMemo(
    () => [
      {
        name: 'Primary Areas',
        ...primaryAreas.reduce(
          (acc, area) => {
            acc.total_impressions += area.total_impressions;
            acc.total_distance += area.total_distance;
            acc.total_time += area.total_time;
            return acc;
          },
          {
            total_impressions: 0,
            total_distance: 0,
            total_time: 0,
          },
        ),
        subRows: primaryAreas,
      },
      {
        name: 'Secondary Areas',
        ...secondaryAreas.reduce(
          (acc, area) => {
            acc.total_impressions += area.total_impressions;
            acc.total_distance += area.total_distance;
            acc.total_time += area.total_time;
            return acc;
          },
          {
            total_impressions: 0,
            total_distance: 0,
            total_time: 0,
          },
        ),
        subRows: secondaryAreas,
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({ columns, data }, useExpanded);

  return (
    <div className="table-responsive">
      <Table striped {...getTableProps()} className="dataTable">
        <thead>
          <tr>
            {headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Inside</td>
            <th className="text-info">
              <FormattedNumber value={driver.inside.total_impressions} />
            </th>
            <th className="text-success">
              <FormattedNumber value={driver.inside.total_distance} isMile />
            </th>
            <th className="text-warning d-none">
              <FormattedNumber value={driver.inside.total_time} isHour />
            </th>
          </tr>
          <tr>
            <td>Outside</td>
            <th className="text-info">
              <FormattedNumber
                value={
                  driver.outside.total_impressions > 0
                    ? driver.outside.total_impressions
                    : 0
                }
              />
            </th>
            <th className="text-success">
              <FormattedNumber value={driver.outside.total_distance} isMile />
            </th>
            <th className="text-warning d-none">
              <FormattedNumber value={driver.outside.total_time} isHour />
            </th>
          </tr>
          <tr>
            <th>Total</th>
            <th className="text-info">
              <FormattedNumber value={driver.total_impressions} />
            </th>
            <th className="text-success">
              <FormattedNumber value={driver.total_distance} isMile />
            </th>
            <th className="text-warning d-none">
              <FormattedNumber value={driver.total_time} isHour />
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

StatisticsDriverAreas.propTypes = {
  areas: PropTypes.array,
  driver: PropTypes.object,
};
export default StatisticsDriverAreas;
