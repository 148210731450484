import React from 'react';
import moment from '../moment';

const Footer = () => (
  <div className="footer">
    <div className="pull-right"></div>
    <div>
      <strong>Copyright</strong> drovo.media &copy; {moment().format('YYYY')}
    </div>
  </div>
);

export default Footer;
