import React from 'react';
import Collapse from '../../Collapse';

const Introduce = () => (
  <Collapse>
    <h2 className="m-b">What is domination?</h2>
    <div className="row">
      <div className="col-xl-7 col-lg-6">
        <p>Dominate a location with a domination of branded vehicles.</p>
        <ul>
          <li>Turn heads and make a massive visual impact</li>
          <li> Hyper-target audiences and locations</li>
          <li>Easily select where, when and how many cars you want</li>
        </ul>
        <p>
          <b>Why not try:</b> Advertise outside events without paying for
          official sponsorship!
        </p>
      </div>
      <div className="col-xl-5 col-lg-6 text-right p-0">
        <img src="/swarm_event.png" className="w-100" alt="" />
      </div>
    </div>
  </Collapse>
);

export default Introduce;
