import React from 'react';
import PropTypes from 'prop-types';
import moment from '../moment';

const CampaignInfo = ({ campaign }) => (
  <div className="project-manager">
    <h3>Campaign detail</h3>
    <p className="small d-none">{campaign.detail}</p>
    <p className="small font-bold text-capitalize d-none">
      <span>
        <i className={`fa fa-circle text-${campaign.status_stylish.style}`}></i>{' '}
        {campaign.status_stylish.label}
      </span>
    </p>
    <h4>
      <i className="fa fa-map"></i> Interested areas
    </h4>
    <ul className="tag-list list-unstyled clear">
      {campaign.areas.map((area) => (
        <li key={`area_${area.id}`}>
          <span>{area.name}</span>
        </li>
      ))}
    </ul>
    <h4>
      <i className="fa fa-car"></i> Vehicle requirements
    </h4>
    <ul className="list-unstyled project-files">
      <li>
        <span>
          Number of vehicles: <b>{campaign.number_of_cars}</b>
        </span>
      </li>
      {campaign.vehicle_colors.length > 0 && (
        <li>
          <span>
            Colours:{' '}
            {campaign.vehicle_colors.map((color) => (
              <i
                key={`color_${color.slug}`}
                className="fa fa-car "
                style={{
                  color: color.color,
                  textShadow:
                    color.slug === 'white' ? '0px 0px 1px #000' : 'none',
                  fontSize: '1.4em',
                  padding: '0px 2px',
                }}
              />
            ))}
          </span>
        </li>
      )}
      {campaign.vehicle_types.length > 0 && (
        <li>
          <span>
            Types:{' '}
            {campaign.vehicle_types.map((type) => (
              <span
                key={`type_${type.id}`}
                className="badge badge-info"
                style={{ margin: '0px 2px' }}
              >
                {type.name}
              </span>
            ))}
          </span>
        </li>
      )}
      {campaign.vehicle_fuel_types.length > 0 && (
        <li>
          <span>
            Fuel Types:{' '}
            {campaign.vehicle_fuel_types.map((type) => (
              <span
                key={`type_${type.id}`}
                className="badge badge-info"
                style={{ margin: '0px 2px' }}
              >
                {type.name}
              </span>
            ))}
          </span>
        </li>
      )}
    </ul>
    {campaign.level && (
      <>
        <h4 className="d-none">
          <i className="fa fa-drivers-license"></i> Accept Level
        </h4>
        <ul className="list-unstyled project-files d-none">
          <li>
            <span>{campaign.level.objLevel.name} and above</span>
          </li>
        </ul>
      </>
    )}
    <h4>
      <i className="fa fa-calendar"></i> Campaign plan
    </h4>
    <ul className="list-unstyled project-files">
      <li>
        <span>
          Commitment:{' '}
          <b>
            {campaign.number_of_months} week
            {campaign.number_of_months > 1 ? 's' : ''}
          </b>
        </span>
      </li>
      <li>
        <span>
          From: <b>{moment(campaign.beginning_date).format('DD/MM/YYYY')}</b>
        </span>
      </li>
      <li>
        <span>
          To: <b>{moment(campaign.end_date).format('DD/MM/YYYY')}</b>
        </span>
      </li>
    </ul>
    {campaign.requirement && (
      <>
        <h4>Others Requirement</h4>
        <p>{campaign.requirement}</p>
      </>
    )}
  </div>
);

CampaignInfo.propTypes = {
  campaign: PropTypes.object,
};
export default CampaignInfo;
