import React from 'react';
import PropTypes from 'prop-types';
import Gallery from '../../../Gallery';

const SwarmGallery = ({ images }) => (
  <div className="ibox float-e-margins">
    <div className="ibox-content">
      <Gallery images={images} />
    </div>
  </div>
);

SwarmGallery.propTypes = {
  images: PropTypes.array,
};

export default SwarmGallery;
