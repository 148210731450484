import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Alert } from 'react-bootstrap';
import moment from '../../moment';
import Collapse from '../../Collapse';
import DatePicker from '../../ExForm/DatePicker';
import TimeKeeper from '../../ExForm/TimeKeeper';
import Field from '../../ExForm/Field';
import { boundCircle, getCenterOfAreas, parseArea } from '../../GMap/helpers';
import DrawMap from '../../DrawMap';
import NumberInput from '../../ExForm/NumberInput';
const Request = ({ campaign, onCancel, onRequest }) => {
  let selectArea = []; // store draw area
  const maxCars = Math.round(campaign.number_of_cars * 0.9);
  let shouldBeDate = moment().format('YYYY-MM-DD');
  if (shouldBeDate > campaign.end_date) shouldBeDate = campaign.end_date;
  if (shouldBeDate < campaign.beginning_date)
    shouldBeDate = campaign.beginning_date;
  return (
    <Collapse className="animated fadeIn ">
      <Formik
        initialValues={{
          location_name: '',
          number_of_cars: maxCars,
          swarm_date: shouldBeDate,
          start_time: moment().format('HH:mm'),
          duration: 30,
          swarm_area_invalid: 'Domination area is required', // just use to store error message of map draw
        }}
        validate={(values) => {
          const errors = {};
          if (!values.location_name)
            errors.location_name = 'This field is required';
          if (values.swarm_area_invalid) {
            errors.swarm_area_invalid = values.swarm_area_invalid;
          }
          if (parseInt(values.number_of_cars, 10) > maxCars) {
            errors.number_of_cars = `You reached over max ${maxCars} cars`;
          }
          if (values.number_of_cars === '') {
            errors.number_of_cars = 'This field is required';
          }
          if (parseInt(values.duration, 10) > 120) {
            errors.duration = `You reached over 2 hours`;
          }
          if (values.duration === '') {
            errors.duration = 'This field is required';
          }

          return errors;
        }}
        onSubmit={(values) => {
          onRequest({
            ...values,
            campaign_id: campaign.id,
            swarm_area: parseArea(selectArea),
          });
        }}
      >
        {(form) => (
          <Form onSubmit={form.handleSubmit}>
            <h2 className="m-b">Creating a Swarm Event</h2>
            Dates and fleet
            <div className="progress">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <div
                className="progress-bar progress-bar-striped bg-success "
                role="progressbar"
                style={{ width: '33%' }}
              ></div>
            </div>
            <h3 className="pt-3">1. Create domination area</h3>
            <div className="row align-items-center">
              <div className="col">
                Draw an area that you want to target on the map and name that
                location in the box.
              </div>
              <div className="col-sm-4 col-md-3">
                <Field
                  classNameWrapper=""
                  name="location_name"
                  form={form}
                  placeholder="e.g. North London"
                />
              </div>
            </div>
            <p>
              Your chosen area should be within 30 miles radius of your Campaign
            </p>
            <DrawMap
              isMultiple={false}
              drawingControlOptions={{ drawingModes: ['circle'] }}
              areas={campaign.areas}
              onLoad={(map) => {
                map.setCenter(getCenterOfAreas(campaign.areas));
                map.setZoom(10);
              }}
              onOverlayUpdate={(area) => {
                selectArea = area;
              }}
              onValidation={(overlay) => {
                if (!overlay)
                  return form.setFieldValue(
                    'swarm_area_invalid',
                    'Domination area is required',
                  );
                if (boundCircle(overlay).radius > 30)
                  return form.setFieldValue(
                    'swarm_area_invalid',
                    'Select an area within 30 miles radius of your main campaign.',
                  );

                return form.setFieldValue('swarm_area_invalid', false);
              }}
            />
            {form.errors.swarm_area_invalid && (
              <Alert className="mt-2" variant="danger">
                {form.errors.swarm_area_invalid}
              </Alert>
            )}
            <h3 className="pt-3">2. Set your fleet</h3>
            <p>
              Choose the number of vehicles. You can reach the max 90% number of
              cars available for your Domination Events.
            </p>
            <div style={{ maxWidth: 150 }}>
              <NumberInput name="number_of_cars" min={1} form={form} />
            </div>
            <h3 className="pt-3">3. Set date and time</h3>
            <p>
              Pick a date, time and duration. The domination duration cannot be
              more than 2 hours.
            </p>
            <div className="row">
              <div className="col-sm-5">
                <div className="form-group">
                  <label>Swarm date</label>
                  <DatePicker
                    name="swarm_date"
                    value={form.values.swarm_date}
                    settings={{
                      minDate: campaign.beginning_date,
                      maxDate: campaign.end_date,
                    }}
                    onApply={(date) => form.setFieldValue('swarm_date', date)}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <TimeKeeper
                  label="Start Time"
                  name="start_time"
                  className="form-control"
                  form={form}
                />
              </div>
              <div className="col-sm-4">
                <NumberInput
                  label="Duration (minutes)"
                  name="duration"
                  form={form}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={onCancel}
              className="btn btn-outline btn-primary btn-sm m-r"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              Save and Continue
            </button>
          </Form>
        )}
      </Formik>
    </Collapse>
  );
};

Request.propTypes = {
  campaign: PropTypes.object,
  onCancel: PropTypes.func,
  onRequest: PropTypes.func,
};

export default Request;
