import {
  AUTH_LAYOUT,
  MENU_GROUP_CAMPAIGN,
  // MENU_USER,
  PROTECT_LAYOUT,
} from './constains/variables';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SetPasswordPage from './pages/SetPasswordPage';
import DashboardPage from './pages/DashboardPage';
// import ChangePasswordPage from './pages/ChangePasswordPage';
// import MyProfilePage from './pages/MyProfilePage';
import CampaignPage from './pages/CampaignPage';
import CampaignDigitalAddOnPage from './pages/CampaignDigitalAddOnPage';
import CampaignCarbonOffsetPage from './pages/CampaignCarbonOffsetPage';
import CampaignSwarmEventsPage from './pages/CampaignSwarmEventsPage';
import CampaignDetailSwarmPage from './pages/CampaignDetailSwarmPage';

export const routes = [
  {
    path: '/auth/login',
    name: 'Login',
    component: LoginPage,
    layout: AUTH_LAYOUT,
  },
  {
    path: '/auth/forgot-password',
    name: 'Forgot Password',
    component: ForgotPasswordPage,
    layout: AUTH_LAYOUT,
  },
  {
    path: '/auth/reset-password/:userId/:resetCode',
    name: 'Reset Password',
    component: SetPasswordPage,
    layout: AUTH_LAYOUT,
  },
  /*
  {
    path: '/user/profile',
    name: 'My Profile',
    component: MyProfilePage,
    layout: PROTECT_LAYOUT,
    menu: MENU_USER,
    attributes: {
      icon: 'fa fa-user',
    },
  },
  {
    path: '/user/change-password',
    name: 'Change Password',
    component: ChangePasswordPage,
    layout: PROTECT_LAYOUT,
    menu: MENU_USER,
    attributes: {
      icon: 'fa fa-lock',
    },
  },
   */
  {
    menu: MENU_GROUP_CAMPAIGN,
    path: '/campaign/:campaignId/digital-add-on',
    name: 'Digital Add-on',
    component: CampaignDigitalAddOnPage,
    layout: PROTECT_LAYOUT,
    attributes: {
      icon: 'fa fa-wifi',
    },
  },

  {
    menu: MENU_GROUP_CAMPAIGN,
    exact: true,
    path: '/campaign/:campaignId/swarming-events/:eventId',
    name: 'Live Domination Event',
    component: CampaignDetailSwarmPage,
    layout: PROTECT_LAYOUT,
    attributes: {
      icon: 'fa fa-location-arrow',
    },
  },
  {
    menu: MENU_GROUP_CAMPAIGN,
    exact: true,
    path: '/campaign/:campaignId/swarming-events',
    name: 'Domination Events',
    component: CampaignSwarmEventsPage,
    layout: PROTECT_LAYOUT,
    attributes: {
      icon: 'fa fa-location-arrow',
    },
  },
  {
    menu: MENU_GROUP_CAMPAIGN,
    path: '/campaign/:campaignId/carbon-offset',
    name: 'Carbon Offset',
    component: CampaignCarbonOffsetPage,
    layout: PROTECT_LAYOUT,
    attributes: {
      icon: 'fa fa-tree',
    },
  },
  {
    menu: MENU_GROUP_CAMPAIGN,
    path: '/campaign/:campaignId',
    name: 'Main Campaign',
    component: CampaignPage,
    layout: PROTECT_LAYOUT,
    attributes: {
      icon: 'fa fa-car',
    },
  },
  {
    path: '/:brand',
    name: 'Dashboard',
    component: DashboardPage,
    layout: PROTECT_LAYOUT,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    layout: PROTECT_LAYOUT,
    attributes: {
      icon: 'fa fa-car',
    },
  },
];
