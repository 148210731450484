import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
const Loading = ({ isLoading, error = '', onClose }) => {
  const show = !!(isLoading || error);
  return (
    <div
      className={`ibox-content sk-loading no-padding ${show ? '' : 'd-none'}`}
    >
      {isLoading && (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      )}
      {!isLoading && (
        <Alert
          variant="danger"
          dismissible
          onClose={() => {
            if (onClose) onClose();
          }}
        >
          {error}
        </Alert>
      )}
    </div>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  onClose: PropTypes.func,
};
export default Loading;
