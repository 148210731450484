import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from '../../moment';
import Collapse from '../../Collapse';
import FormattedNumber from '../../Intl/FormattedNumber';
import { useCampaign } from '../../../providers/CampaignProvider';

const Live = ({ event }) => {
  const { campaign } = useCampaign();

  const startTime = moment(event.start_time, 'HH:mm:ss');

  return (
    <Collapse className="animated fadeIn ">
      <h2 className="m-b">
        {moment(event.swarm_date).format('Do MMMM YYYY')} - Swarm Event is
        LIVE!:
      </h2>
      <p className="m-t-md">
        {'Today, '}
        {startTime.format('hh:mmA')}
        {' - '}
        {startTime.add(event.duration, 'm').format('hh:mmA')}
        {', '}
        {event.location_name}
        {', '}{' '}
        <FormattedNumber
          value={event.number_of_cars}
          surfix={event.number_of_cars > 1 ? 'Cars' : 'Car'}
        />
      </p>
      <Link
        className="btn btn-primary btn-sm m-r"
        to={`/campaign/${campaign.id}/swarming-events/${event.id}`}
      >
        Show detail
      </Link>
    </Collapse>
  );
};

Live.propTypes = {
  event: PropTypes.object,
};

export default Live;
