import { OverlayView, StreetViewPanorama } from '@react-google-maps/api';
import React from 'react';
import PropTypes from 'prop-types';

const StreetView = ({
  onClose,
  position = { lat: 49.2853171, lng: -123.1119202 },
  visible = false,
}) => (
  <StreetViewPanorama
    onCloseclick={onClose}
    position={position}
    visible={visible}
  >
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_LAYER}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -(height / 2),
      })}
    >
      <div
        style={{
          background: `red`,
          color: `white`,
          padding: 10,
          borderRadius: `50%`,
        }}
      >
        OverlayView
      </div>
    </OverlayView>
  </StreetViewPanorama>
);

StreetView.propTypes = {
  position: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StreetView;
