import React from 'react';
import PropTypes from 'prop-types';
import { useIntercom } from 'react-use-intercom';
import moment from '../../moment';
import Collapse from '../../Collapse';

const Live = ({ campaign }) => {
  const { showNewMessages } = useIntercom();
  const days = moment(campaign.end_date).diff(moment(), 'days');

  return (
    <Collapse className="animated fadeIn ">
      <h2 className="m-b">Digital Campaign: {campaign.name} - LIVE</h2>
      {days > 0 && `Campaign is running: ${days} days left`}
      <div className="progress">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-success "
          role="progressbar"
          style={{ width: '25%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-warning "
          role="progressbar"
          style={{ width: '25%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped bg-danger "
          role="progressbar"
          style={{ width: '25%' }}
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar progress-bar-striped "
          role="progressbar"
          style={{ width: '25%' }}
        />
      </div>
      {/* digital && digital.report && (
        <>
          <hr />
          <iframe
            src={digital.report}
            frameBorder="0"
            style={{ border: 0, width: '100%', minHeight: 450 }}
            allowFullScreen
          />
        </>
      ) */}
      <p className="m-t">If you have questions, contact our team.</p>
      <button
        type="button"
        onClick={() =>
          showNewMessages(
            'Hello, I would like to know about my digital request process.',
          )
        }
        className="btn btn-primary btn-sm m-r"
      >
        Contact The Team
      </button>
    </Collapse>
  );
};

Live.propTypes = {
  campaign: PropTypes.object,
};

export default Live;
