import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfoWindow, Polygon } from '@react-google-maps/api';
import { convertArrayToGeoArray } from '../GMap/helpers';

const AreaLayer = ({ area }) => {
  const [state, setState] = useState({
    toggleInfo: false,
    mousePosition: null,
  });

  const polygon = (path, index) => (
    <Polygon
      key={index.toString()}
      path={convertArrayToGeoArray(path)}
      options={{
        geodesic: true,
        strokeColor: '#399eff',
        strokeOpacity: 0.5,
        strokeWeight: 1.5,
        fillColor: '#99c4ff',
        fillOpacity: 0.2,
      }}
      onClick={(evt) => {
        setState({
          toggleInfo: true,
          mousePosition: evt.latLng,
        });
      }}
    />
  );

  const polygons = [];
  if (area.geometry && area.geometry.outerBoundaryIs) {
    area.geometry.outerBoundaryIs.forEach((path, index) => {
      if (path[0].length > 2) {
        path.map((subPath, idx) =>
          polygons.push(polygon(subPath, `${idx}_${index}`)),
        );
      } else {
        polygons.push(polygon(path, index.toString()));
      }
    });
  }

  if (polygons.length > 0) {
    return (
      <>
        {polygons}
        {state.toggleInfo && (
          <InfoWindow
            onCloseClick={() =>
              setState({
                ...state,
                toggleInfo: false,
              })
            }
            position={state.mousePosition}
          >
            <div>{area.name}</div>
          </InfoWindow>
        )}
      </>
    );
  }
  return null;
};
AreaLayer.propTypes = {
  area: PropTypes.object,
};
export default AreaLayer;
